import revive_payload_client_4sVQNw7RlN from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/stocklear/nuxt-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/stocklear/nuxt-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/stocklear/nuxt-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/builds/stocklear/nuxt-frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_t2GMTTFnMT from "/builds/stocklear/nuxt-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_WLsn00x1qh from "/builds/stocklear/nuxt-frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_W86eSGFqyZ from "/builds/stocklear/nuxt-frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/builds/stocklear/nuxt-frontend/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/builds/stocklear/nuxt-frontend/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/stocklear/nuxt-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/stocklear/nuxt-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import stocklear_gtm_RERtfy6g4D from "/builds/stocklear/nuxt-frontend/plugins/stocklear-gtm.js";
import api_QQzOodE9HA from "/builds/stocklear/nuxt-frontend/plugins/api.js";
import date_aCcDt3CXqN from "/builds/stocklear/nuxt-frontend/plugins/date.js";
import directives_GDwrTGmylM from "/builds/stocklear/nuxt-frontend/plugins/directives.js";
import me_qQpC7QiiZK from "/builds/stocklear/nuxt-frontend/plugins/me.js";
import primevue_TdXjRgL1MA from "/builds/stocklear/nuxt-frontend/plugins/primevue.js";
import toasts_FNIVs44kzr from "/builds/stocklear/nuxt-frontend/plugins/toasts.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_t2GMTTFnMT,
  plugin_WLsn00x1qh,
  plugin_client_W86eSGFqyZ,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  stocklear_gtm_RERtfy6g4D,
  api_QQzOodE9HA,
  date_aCcDt3CXqN,
  directives_GDwrTGmylM,
  me_qQpC7QiiZK,
  primevue_TdXjRgL1MA,
  toasts_FNIVs44kzr
]
// Numéros des jours du weekend
const weekend = [0, 6];

/**
 * Renvoie la date actuelle, synchronisée avec le serveur
 * @returns {Date}
 */
const now = (timeToAdd) => {
    return new Date(Date.now() + timeToAdd);
};

/**
 * Renvoie la date actuelle, synchronisée avec le serveur, arrondie à la seconde
 * @returns {number}
 */
const getTime = (timeToAdd) => {
    return Math.floor(now(timeToAdd) / 1000);
};

/**
 * Renvoie le nombre d'heures restantes en comptant le weekend
 * @param {Number} timeToAdd
 * @param {String} start
 * @param {Number} duration
 * @returns {Number}
 */
const getRemainingHours = (timeToAdd, start, duration) => {
    let currentDate = new Date(start);
    let remainingDuration = duration * 3600;
    let realDuration = duration * 3600;

    // Parcours les jours jusqu'à la fin de la durée
    // pour calculer la durée réelle (durée + weekends)
    while (remainingDuration > 0) {
        // Secondes du jour
        let currentSeconds =
            currentDate.getHours() * 3600 -
            currentDate.getMinutes() * 60 -
            currentDate.getSeconds();

        // Si le jour actuel est le weekend, ajoute 24h
        if (weekend.includes(currentDate.getDay())) {
            realDuration += 24 * 3600 - currentSeconds;
        } else {
            // Sinon décrémente la durée restante
            remainingDuration -= 24 * 3600 - currentSeconds;
        }

        // Passe au jour suivant
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
    }

    // Calcule le timestamp de fin avec la vrai durée
    let endingTimestamp = new Date(start).getTime() + realDuration * 1000;

    // Calcule la durée restante en ms
    let diffTimestamp = endingTimestamp - now(timeToAdd).getTime();

    // Retourne la durée restante en heures
    return Math.ceil(diffTimestamp / 3600 / 1000);
};

/**
 * - Protocole NTP : récupère la différence entre le temps serveur et le temps client (timeToAdd)
 * - Déclare une variable d'état `currentTime` qui contient le timestamp actuel
 * - Démarre un webworker pour actualiser le currentTime toutes les secondes à 0ms
 * - Expose la fonction `now` qui renvoie la date actuelle ajustée
 * (Inspiré par : https://stackoverflow.com/questions/1638337)
 */
export default defineNuxtPlugin(async ({ $api }) => {
    // Initialisation de la date actuelle
    const currentTime = useState('currentTime', () => getTime(0));

    let timeToAdd = 0;

    // Récupère le timestamp client avant la requête
    const clientTimestamp = Date.now();

    // Récupère le timestamp du serveur
    const { data: serverTimestamp } = await $api.get('v1/timestamp');

    // Récupère le timestamp client après la requête
    const nowTimestamp = Date.now();

    // Calcule la latence réseau
    const networkLatency = (nowTimestamp - clientTimestamp) / 2;

    // Calcule l'heure correcte selon le serveur
    const adjustedServerTime = parseInt(serverTimestamp.value) + networkLatency;

    // Calcule la différence entre le temps serveur ajusté et le temps local
    timeToAdd = adjustedServerTime - nowTimestamp;

    // Met à jour le timestamp actuel
    currentTime.value = getTime(timeToAdd);

    if (process.client) {
        // Initialisation du countdown
        let worker = new Worker('/countdown.js');
        worker.postMessage(0);
        worker.onmessage = () => {
            currentTime.value = getTime(timeToAdd);
        };
    }

    return {
        provide: {
            date: {
                now: () => now(timeToAdd),
                getRemainingHours: (start, duration) =>
                    getRemainingHours(timeToAdd, start, duration),
            },
        },
    };
});

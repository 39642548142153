import { defineStore } from 'pinia';
import { CorporationBilling, CorporationStatus, UserRoles } from '~/enum';

export const useUserStore = defineStore('user', {
    state: () => ({
        me: null,
    }),

    actions: {
        /**
         * Returns the current user from cookies
         * @returns {Object}
         */
        get() {
            return this.me;
        },

        set(user) {
            this.me = user;
        },

        /**
         * Returns if the user cookie is set
         * @returns {Boolean}
         */
        check() {
            return this.me != null;
        },

        /**
         * Api request to get the current user and set the cookie
         */
        async refresh() {
            const { $api } = useNuxtApp();

            await $api.get('v1/me', {
                onSuccess: (response) => {
                    this.me = response?.data;
                },
            });
        },

        /**
         * Remove the user cookie
         */
        forget() {
            this.me = null;
        },

        can(permission) {
            return this.me.permissions.includes(permission);
        },

        /**
         * Return true if the user can bid on offers
         */
        canBid() {
            return this.isValidated() && this.isBuyer();
        },

        /**
         * Returns user new IBAN if he already had one before
         */
        getIban() {
            if (!this.me?.corporation?.stripe_iban) {
                return null;
            }

            return this.me?.corporation?.stripe_funding_instruction_default;
        },

        /**
         * Returns the user layout
         * @returns {String}
         */
        getLayout() {
            switch (this.me?.role) {
                case UserRoles.BUYER:
                    return 'frontend-account';

                default:
                    return 'backend';
            }
        },

        /**
         * Retourne l'adresse de livraison par défaut de l'utilisateur
         */
        getDefaultDeliveryAddressAsString() {
            const warehouse =
                this.me?.corporation?.warehouses.length > 0
                    ? this.me?.corporation?.warehouses[0]
                    : null;

            // Si l'utilisateur n'a pas d'addresse de livraison, on retourne l'addresse de facturation
            if (!warehouse) {
                return this.getBillingAddressAsString();
            }

            let address = warehouse.address_line_1;

            if (warehouse.address_line_2) {
                address += ',';
                address += warehouse.address_line_2;
            }

            if (warehouse.address_department) {
                address += ',';
                address += warehouse.address_department;
            }

            address += ',';
            address += `${warehouse.address_postal_code}, ${warehouse.address_city}`;

            if (warehouse.address_district) {
                address += ',';
                address += warehouse.address_district;
            }

            if (warehouse.country) {
                address += ',';
                address += warehouse.country;
            }

            return address;
        },

        /**
         * Returns the billing address of the user's corporation
         */
        getBillingAddressAsString() {
            let corporation = this.me?.corporation;

            if (!corporation) {
                return '';
            }

            let address = corporation.address_line_1;

            if (corporation.address_line_2) {
                address += ',';
                address += corporation.address_line_2;
            }

            if (corporation.address_department) {
                address += ',';
                address += corporation.address_department;
            }

            address += ',';
            address += `${corporation.address_postal_code}, ${corporation.address_city}`;

            if (corporation.address_district) {
                address += ',';
                address += corporation.address_district;
            }

            if (corporation.country) {
                address += ',';
                address += corporation.country.name;
            }

            return address;
        },

        /**
         * Returns true if the user is an admin
         * @returns {Boolean}
         */
        isAdmin(strict) {
            if (strict) {
                return this.me?.role === UserRoles.ADMIN;
            }

            return UserRoles.managers().includes(this.me?.role);
        },

        /**
         * Returns true if the user is a buyer
         * @returns {Boolean}
         */
        isBuyer() {
            return this.me?.role === UserRoles.BUYER;
        },

        /**
         * Returns true if the user is a logistician
         * @returns {Boolean}
         */
        isLogistician() {
            return UserRoles.logisticians().includes(this.me?.role);
        },

        /**
         * Returns true if the user is a SAV agent
         * @returns {Boolean}
         */
        isSav() {
            return this.me?.role === UserRoles.OPERATOR_SAV_TRANSPORT;
        },

        /**
         * Returns true if the user is a seller
         * @returns {Boolean}
         */
        isSeller(corporationId) {
            return (
                this.me?.role === UserRoles.SELLER &&
                (!corporationId || this.me?.corporation?.id === corporationId)
            );
        },

        /**
         * Returns true if the user is a transporter
         * @returns {Boolean}
         */
        isTransporter() {
            return UserRoles.transporters().includes(this.me?.role);
        },

        /**
         * Returns true if the user is a buyer or an operator buyer
         * @returns {Boolean}
         */
        isOperatorBuyer() {
            return this.isAdmin() || this.me?.role === UserRoles.OPERATOR_BUYER;
        },

        /**
         * Returns true if the user is a buyer or an operator buyer
         * @returns {Boolean}
         */
        isAccountant() {
            return this.isAdmin() || this.me?.role === UserRoles.ACCOUNTANT;
        },

        /**
         * Returns if the user is considered as validated
         *
         * @returns {Boolean}
         */
        isValidated() {
            // If the user is not logged in
            if (!this.me) {
                return false;
            }

            // Validate all buyers that has a corporation in status
            // accepted, noshow or suspended
            if (UserRoles.buyers().includes(this.me?.role)) {
                return [
                    CorporationStatus.ACCEPTED,
                    CorporationStatus.NOSHOW,
                    CorporationStatus.SUSPENDED,
                ].includes(this.me?.corporation?.status);
            }

            return true;
        },

        /**
         * Returns if the user is in NoShow status
         * @returns {Boolean}
         */
        isNoShow() {
            return this.me?.corporation?.status === CorporationStatus.NOSHOW;
        },

        isCertificateMissing() {
            return (
                this.me?.corporation?.status === CorporationStatus.WAITING_INFO &&
                !this.me?.corporation?.has_certificate
            );
        },

        isCommissionaire() {
            return CorporationBilling.commissionaires().includes(this.me?.corporation.billing);
        },

        isMandataire() {
            return CorporationBilling.mandataires().includes(this.me?.corporation.billing);
        },

        /**
         * Log in as a specific user
         * @param {String} userId
         */
        async impersonate(userId) {
            const { $api, $localePath } = useNuxtApp();

            await $api.post('v1/impersonate', {
                body: {
                    id: userId,
                },
                onSuccess: async (response) => {
                    this.set(response?.data);

                    switch (this.me.role) {
                        case UserRoles.SELLER:
                            return await navigateTo($localePath('offers'));

                        case UserRoles.LOGISTICIAN:
                            return await navigateTo($localePath('trucks'));

                        default:
                            return await navigateTo($localePath('account'));
                    }
                },
            });
        },
    },
});

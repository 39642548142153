import validate from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import main_45global from "/builds/stocklear/nuxt-frontend/middleware/main.global.ts";
import manifest_45route_45rule from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  main_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/stocklear/nuxt-frontend/middleware/auth.ts"),
  guest: () => import("/builds/stocklear/nuxt-frontend/middleware/guest.ts"),
  "is-admin": () => import("/builds/stocklear/nuxt-frontend/middleware/isAdmin.ts"),
  "is-logistician": () => import("/builds/stocklear/nuxt-frontend/middleware/isLogistician.ts"),
  "is-seller": () => import("/builds/stocklear/nuxt-frontend/middleware/isSeller.ts"),
  "is-transporter": () => import("/builds/stocklear/nuxt-frontend/middleware/isTransporter.ts"),
  validated: () => import("/builds/stocklear/nuxt-frontend/middleware/validated.ts"),
  waiting: () => import("/builds/stocklear/nuxt-frontend/middleware/waiting.ts")
}
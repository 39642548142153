import { useUserStore } from '~/stores/user';

/**
 * Define the $me plugin
 */
export default defineNuxtPlugin((nuxtApp) => {
    const userStore = useUserStore();

    return {
        provide: {
            me: userStore,
        }
    }
});

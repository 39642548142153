
// @ts-nocheck
import locale__builds_stocklear_nuxt_frontend_lang_en_json from "../lang/en.json";
import locale__builds_stocklear_nuxt_frontend_lang_fr_json from "../lang/fr.json";
import locale__builds_stocklear_nuxt_frontend_lang_de_json from "../lang/de.json";
import locale__builds_stocklear_nuxt_frontend_lang_es_json from "../lang/es.json";
import locale__builds_stocklear_nuxt_frontend_lang_nl_json from "../lang/nl.json";


export const localeCodes =  [
  "en",
  "uk",
  "fr",
  "de",
  "es",
  "nl"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.json", load: () => Promise.resolve(locale__builds_stocklear_nuxt_frontend_lang_en_json), cache: true }],
  "uk": [{ key: "../lang/en.json", load: () => Promise.resolve(locale__builds_stocklear_nuxt_frontend_lang_en_json), cache: true }],
  "fr": [{ key: "../lang/fr.json", load: () => Promise.resolve(locale__builds_stocklear_nuxt_frontend_lang_fr_json), cache: true }],
  "de": [{ key: "../lang/de.json", load: () => Promise.resolve(locale__builds_stocklear_nuxt_frontend_lang_de_json), cache: true }],
  "es": [{ key: "../lang/es.json", load: () => Promise.resolve(locale__builds_stocklear_nuxt_frontend_lang_es_json), cache: true }],
  "nl": [{ key: "../lang/nl.json", load: () => Promise.resolve(locale__builds_stocklear_nuxt_frontend_lang_nl_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "language": "en",
      "protocol": "https",
      "subDomain": "joblot",
      "domain": "joblot.stocklear.eu",
      "api": "api.stocklear.eu",
      "gtmId": "GTM-K8F7H4K",
      "files": [
        "/builds/stocklear/nuxt-frontend/lang/en.json"
      ]
    },
    {
      "code": "uk",
      "language": "en",
      "protocol": "https",
      "subDomain": "joblot",
      "domain": "joblot.stocklear.co.uk",
      "api": "api.stocklear.co.uk",
      "gtmId": "GTM-TZBHKGR",
      "files": [
        "/builds/stocklear/nuxt-frontend/lang/en.json"
      ]
    },
    {
      "code": "fr",
      "language": "fr",
      "protocol": "https",
      "subDomain": "lots",
      "domain": "lots.stocklear.fr",
      "api": "api.stocklear.fr",
      "gtmId": "GTM-KLKM79K",
      "files": [
        "/builds/stocklear/nuxt-frontend/lang/fr.json"
      ]
    },
    {
      "code": "de",
      "language": "de",
      "protocol": "https",
      "subDomain": "lose",
      "domain": "lose.stocklear.de",
      "api": "api.stocklear.de",
      "gtmId": "GTM-TQCRH45",
      "files": [
        "/builds/stocklear/nuxt-frontend/lang/de.json"
      ]
    },
    {
      "code": "es",
      "language": "es",
      "protocol": "https",
      "subDomain": "lotes",
      "domain": "lotes.stocklear.es",
      "api": "api.stocklear.es",
      "gtmId": "GTM-TWB4MPS",
      "files": [
        "/builds/stocklear/nuxt-frontend/lang/es.json"
      ]
    },
    {
      "code": "nl",
      "language": "nl",
      "protocol": "https",
      "subDomain": "partijen",
      "domain": "partijen.stocklear.nl",
      "api": "api.stocklear.nl",
      "gtmId": "GTM-M2842M9",
      "files": [
        "/builds/stocklear/nuxt-frontend/lang/nl.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "https://lots.stocklear.fr",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "en": {
        "path": "/",
        "name": "index__en"
      },
      "uk": {
        "path": "/",
        "name": "index__uk"
      },
      "fr": {
        "path": "/",
        "name": "index__fr"
      },
      "de": {
        "path": "/",
        "name": "index__de"
      },
      "es": {
        "path": "/",
        "name": "index__es"
      },
      "nl": {
        "path": "/",
        "name": "index__nl"
      }
    },
    "login/index": {
      "en": "/login",
      "fr": "/connexion",
      "de": "/einloggen",
      "es": "/conexion",
      "nl": "/inloggen"
    },
    "login/redirect": {
      "es": "/conexión",
      "nl": "/aanmelden"
    },
    "register/buyer": {
      "en": "/register/buyer",
      "fr": "/inscription/acheteur",
      "de": "/registrieren/kaufer",
      "es": "/registro/compradores",
      "nl": "/inschrijving/koper"
    },
    "register/activity": {
      "en": "/account/activity",
      "fr": "/compte/activite"
    },
    "register/profile": {
      "en": "/account/profile",
      "fr": "/compte/profil"
    },
    "register/confirmation": {
      "en": "/account/confirmation",
      "fr": "/compte/confirmation"
    },
    "marketplace/index": {
      "en": "/p/[...slug]",
      "fr": "/p/[...slug]",
      "de": "/p/[...slug]",
      "es": "/p/[...slug]",
      "nl": "/p/[...slug]"
    },
    "marketplace/redirect": {
      "en": "/marketplace",
      "fr": "/marketplace",
      "de": "/marketplace",
      "es": "/marketplace",
      "nl": "/marketplace"
    },
    "marketplace/detail/[id]": {
      "en": "/auction/[id]",
      "fr": "/enchere/[id]",
      "es": "/subastas/[id]",
      "de": "/auktion/[id]",
      "nl": "/veiling/[id]"
    }
  },
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en",
    "protocol": "https",
    "subDomain": "joblot",
    "domain": "joblot.stocklear.eu",
    "api": "api.stocklear.eu",
    "gtmId": "GTM-K8F7H4K",
    "files": [
      {
        "path": "/builds/stocklear/nuxt-frontend/lang/en.json"
      }
    ]
  },
  {
    "code": "uk",
    "language": "en",
    "protocol": "https",
    "subDomain": "joblot",
    "domain": "joblot.stocklear.co.uk",
    "api": "api.stocklear.co.uk",
    "gtmId": "GTM-TZBHKGR",
    "files": [
      {
        "path": "/builds/stocklear/nuxt-frontend/lang/en.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr",
    "protocol": "https",
    "subDomain": "lots",
    "domain": "lots.stocklear.fr",
    "api": "api.stocklear.fr",
    "gtmId": "GTM-KLKM79K",
    "files": [
      {
        "path": "/builds/stocklear/nuxt-frontend/lang/fr.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de",
    "protocol": "https",
    "subDomain": "lose",
    "domain": "lose.stocklear.de",
    "api": "api.stocklear.de",
    "gtmId": "GTM-TQCRH45",
    "files": [
      {
        "path": "/builds/stocklear/nuxt-frontend/lang/de.json"
      }
    ]
  },
  {
    "code": "es",
    "language": "es",
    "protocol": "https",
    "subDomain": "lotes",
    "domain": "lotes.stocklear.es",
    "api": "api.stocklear.es",
    "gtmId": "GTM-TWB4MPS",
    "files": [
      {
        "path": "/builds/stocklear/nuxt-frontend/lang/es.json"
      }
    ]
  },
  {
    "code": "nl",
    "language": "nl",
    "protocol": "https",
    "subDomain": "partijen",
    "domain": "partijen.stocklear.nl",
    "api": "api.stocklear.nl",
    "gtmId": "GTM-M2842M9",
    "files": [
      {
        "path": "/builds/stocklear/nuxt-frontend/lang/nl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

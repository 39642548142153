import { useToastStore } from '~/stores/toast';

/**
 * Add a toast to the store
 * @param {Object} toast 
 */
const add = (toast) => {
    const toastStore = useToastStore();
    toastStore.push(toast);
}

/**
 * Show all toasts from the store
 * then reset the store
 * @param {Object} nuxtApp
 */
const show = (nuxtApp) => {
    const toastStore = useToastStore();

    setTimeout(() => {
        toastStore.toasts.forEach(toast => {
            nuxtApp.vueApp.config.globalProperties.$toast.add(toast);
        });

        toastStore.reset();
    }, 100);
}

/**
 * Add a toast to the store
 * then show all toasts from the store
 * then reset the store
 * @param {Object} nuxtApp 
 * @param {Object} toast 
 */
const flash = (nuxtApp, toast) => {
    add(toast);
    show(nuxtApp);
}

/**
 * Clear all toasts
 */
const clear = (nuxtApp) => {
    nuxtApp.vueApp.config.globalProperties.$toast.remove();
}

/**
 * Define the toast plugin
 */
export default defineNuxtPlugin((nuxtApp) => {
    return {
        provide: {
            toasts: {
                show: () => { show(nuxtApp) },
                add: (toast) => { add(toast) },
                flash: (toast) => { flash(nuxtApp, toast) },
                clear: () => { clear(nuxtApp) }
            },
        }
    }
});

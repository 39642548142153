import { useUserStore } from '~/stores/user';

// Default Google Tag Manager ID (.fr)
let gtmKey = 'GTM-KLKM79K';
let link = 'https://tss.stocklear.fr/digutxg6zkxw6mc.js?id=';

// Determine Google Tag Manager ID based on domain suffix
if (document.location.host.endsWith('.es')) {
    gtmKey = 'GTM-TWB4MPS';
    link = 'https://www.googletagmanager.com/gtm.js?id=';
} else if (document.location.host.endsWith('.de')) {
    gtmKey = 'GTM-TQCRH45';
    link = 'https://www.googletagmanager.com/gtm.js?id=';
} else if (document.location.host.endsWith('.it')) {
    gtmKey = 'GTM-NTVTFLB';
    link = 'https://www.googletagmanager.com/gtm.js?id=';
} else if (document.location.host.endsWith('.uk')) {
    gtmKey = 'GTM-TZBHKGR';
    link = 'https://www.googletagmanager.com/gtm.js?id=';
} else if (document.location.host.endsWith('.eu')) {
    gtmKey = 'GTM-K8F7H4K';
    link = 'https://www.googletagmanager.com/gtm.js?id=';
} else if (document.location.host.endsWith('.nl')) {
    gtmKey = 'GTM-M2842M9';
    link = 'https://www.googletagmanager.com/gtm.js?id=';
}

export default defineNuxtPlugin(() => {
    if (process.server) {
        return;
    }

    // Don't load GTM in dev mode
    if (useRuntimeConfig().public.appEnv.startsWith('dev')) {
        window['dataLayer'] = [];
        return;
    }

    const userStore = useUserStore();
    const me = userStore.get();

    // GTM Pageview - Toutes les pages
    let pageviewEvent = {
        event: 'pageview',
        event_name: 'pageview',
        language: window.location.host,
        user_id: me?.id,
        join_group: me?.corporation?.hubspot_status,
    };

    (function (w, d, s, l, i, pageviewEvent) {
        w[l] = w[l] || [];
        w[l].push(pageviewEvent);
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        let f = d.getElementsByTagName(s)[0];
        let j = d.createElement(s);
        let dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = link + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmKey, pageviewEvent);

    // Add Google Tag Manager <noscript> to the <body>
    document.body.insertAdjacentHTML(
        'afterbegin',
        `<noscript><iframe src="${link}${gtmKey}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
    );
});

<script setup>
    // Save the viewport in cookies
    const viewport = useViewport();
    const viewportCookie = useCookie('viewport');
    watch(viewport.breakpoint, (newBreakpoint) => {
        viewportCookie.value = newBreakpoint;
    });

    const nuxtApp = useNuxtApp();

    const me = computed(() => {
        return nuxtApp.$me.get();
    });

    const localePath = useLocalePath();

    const loaded = ref(false);

    const leaveImpersonation = async () => {
        await nuxtApp.$api.post('v1/impersonate/leave');
        window.location.href = localePath('account');
    };

    // Hide loader when page finish
    nuxtApp.hook('page:finish', () => {
        loaded.value = true;

        setTimeout(() => {
            nuxtApp.$toasts.show();
        }, 20);
    });

    const config = useRuntimeConfig();

    if (config.public.appEnv === 'production') {
        // Hubspot script
        useHead({
            script: [
                {
                    type: 'text/javascript',
                    id: 'hs-script-loader',
                    async: true,
                    defer: true,
                    src: '//js-eu1.hs-scripts.com/25337956.js',
                    body: true,
                },
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: '/images/favicon/apple-touch-icon.png',
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: '/images/favicon/favicon-32x32.png',
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: '/images/favicon/favicon-16x16.png',
                },
                { rel: 'manifest', href: '/images/favicon/manifest.json' },
                {
                    rel: 'mask-icon',
                    href: '/images/favicon/safari-pinned-tab.svg',
                    color: '#2969ff',
                },
            ],
            meta: [
                { name: 'msapplication-TileColor', content: '#ffffff' },
                { name: 'theme-color', content: '#ffffff' },
            ],
        });
    }
</script>

<template>
    <div id="app">
        <NuxtLoadingIndicator :throttle="1" />
        <NuxtPage />

        <div v-if="me?.is_impersonating" id="impersonating">
            <div class="flex gap-1 text-center">
                {{
                    `${$t('Connected as')} ${
                        me?.corporation?.name || `${me?.first_name} ${me?.name}`
                    } (${me?.email})`
                }}
            </div>

            <LazyPrimevueButton
                :label="$t('Logout')"
                severity="success"
                icon="material-symbols:lock"
                @click="leaveImpersonation"
            />
        </div>
    </div>
</template>

const getOptions = (nuxtApp, options) => {
    const xsrfToken = useCookie('XSRF-TOKEN');
    const localeProperties = nuxtApp.$i18n.localeProperties.value;

    // Initialize the options object if not provided
    if (options === undefined) {
        options = {};
    }

    // Initialize the headers object with options headers
    let headers = {
        accept: 'application/json',
        ...options?.headers,
    }

    // Stringify the body if it is an object and not an instance of FormData
    if (typeof options?.body === 'object' && ! (options?.body instanceof FormData)) {
        options.body = JSON.stringify(options.body);
    }

    // Add the XSRF-TOKEN header if the cookie is set
    if (xsrfToken?.value !== null) {
        headers['X-XSRF-TOKEN'] = xsrfToken.value;
    }

    // Add the referer and origin headers if the request is made server side
    if (process.server) {
        headers = {
            ...headers,
            ...useRequestHeaders(['cookie']),
            // https://lots.stocklear.fr
            referer: `${localeProperties.protocol}://${localeProperties.domain}`
        }
    }

    let redirect = null;
    if (options.redirect) {
        redirect = options.redirect;
        delete options.redirect;
    }

    // If options does not contain onResponse closure
    // set one by default to handle errors and call options.onSuccess if provided
    if (! options?.onResponse) {
        options.onResponse = ({response}) => {
            if (options.onBeforeResponse) {
                options.onBeforeResponse(response._data);
            }

            if (response.status >= 400) {
                console.error(response);
                let toast = {
                    severity: 'error',
                    summary: nuxtApp.$i18n.t('Whoops!'),
                    detail: response._data.message || response.statusText,
                    group: 'tr',
                    life: 3000
                };

                // If redirect, add toast without flash
                // then redirect
                if (redirect) {
                    nuxtApp.$toasts.add(toast);
                    return navigateTo(redirect);
                }

                // If no redirect, flash the toast
                nuxtApp.$toasts.flash(toast);

                if (options.afterResponse) {
                    options.afterResponse(response._data);
                }

                if (options.afterError) {
                    options.afterError(response._data);
                }

                return;
            }

            if (options.onSuccess) {
                options.onSuccess(response._data);
            }

            if (options.afterResponse) {
                options.afterResponse(response._data);
            }
        }
    }

    return {
        // https://api.stocklear.fr
        baseURL: `${localeProperties.protocol}://${localeProperties.api}`,
        headers,
        credentials: 'include',
        deep: false,
        ...options,
    };
}

export default defineNuxtPlugin((nuxtApp) => {
    return {
        provide: {
            api: {
                get: (url, options, simpleFetch) => {
                    if (simpleFetch) {
                        return $fetch(url, getOptions(nuxtApp, options))
                    }

                    return useFetch(url, getOptions(nuxtApp, options))
                },

                post: async (url, options) => {
                    if (options?.refresh !== false) {
                        await nuxtApp.$me.refresh();
                    }

                    return await $fetch(url, {
                        method: 'POST',
                        ...getOptions(nuxtApp, options)
                    })
                },

                put: async (url, options) => {
                    if (options?.refresh !== false) {
                        await nuxtApp.$me.refresh();
                    }

                    return await $fetch(url, {
                        method: 'PUT',
                        ...getOptions(nuxtApp, options)
                    })
                },

                delete: async (url, options) => {
                    if (options?.refresh !== false) {
                        await nuxtApp.$me.refresh();
                    }

                    return await $fetch(url, {
                        method: 'DELETE',
                        ...getOptions(nuxtApp, options)
                    })
                }
            }
        }
    }
})

import { usePrimeVue } from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import fr from 'primelocale/fr.json';
import en from 'primelocale/en.json';
import es from 'primelocale/es.json';
import de from 'primelocale/de.json';
import it from 'primelocale/it.json';
import nl from 'primelocale/nl.json';

// We merge all the locales into one object
const primevueLocales = { ...fr, ...en, ...es, ...de, ...it, ...nl };

// We also need to add to this object the key "uk" that are not present in the locales
primevueLocales["uk"] = primevueLocales["en"];

export default defineNuxtPlugin(({ vueApp, $i18n }) => {
    usePrimeVue().config.locale = primevueLocales[$i18n.locale.value];
    vueApp.use(ConfirmationService);
    vueApp.use(ToastService);
});

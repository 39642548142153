export const CorporationStatus = Object.freeze({
    WAITING_INFO: 0,
    WAITING: 1,
    ACCEPTED: 2,
    REFUSED: 3,
    SUSPENDED: 4,
    NOSHOW: 5,
    labels: [
        { value: 0, text: 'Waiting information' },
        { value: 1, text: 'Waiting' },
        { value: 2, text: 'Accepted' },
        { value: 3, text: 'Refused' },
        { value: 4, text: 'Suspended' },
        { value: 5, text: 'No show' },
    ],
    severity: {
        0: 'warning',
        1: 'danger',
        2: 'success',
        3: 'secondary',
        4: 'warning',
        5: 'danger',
    },
    getStatusText(status) {
        let statusText = null;

        switch (status) {
            case CorporationStatus.WAITING:
            case CorporationStatus.WAITING_INFO:
                statusText = 'is awaiting validation';
                break;

            case CorporationStatus.SUSPENDED:
            case CorporationStatus.REFUSED:
                statusText = 'is not active';
                break;
        }

        return statusText;
    },
});

export const CorporationStripeStatus = Object.freeze({
    WAITING: 0,
    ONBOARDING: 1,
    ONBOARDING_DOC: 2,
    VERIFIED: 3,
    NA: 4,
    labels: [
        { value: 0, text: 'Waiting information' },
        { value: 1, text: 'Waiting onboarding' },
        { value: 2, text: 'Waiting onboarding doc' },
        { value: 3, text: 'Verified' },
        { value: 4, text: 'Not applicable' },
    ],
    badges: {
        0: 'badge-warning',
        1: 'badge-warning',
        2: 'badge-warning',
        3: 'badge-success',
        4: '',
    },
});

export const CorporationBilling = Object.freeze({
    STOCKLEAR: 2,
    YES: 1,
    EXTERN: 3,
    NO: 0,
    labels: [
        { value: 2, text: 'Commissionaire Stripe' },
        { value: 1, text: 'Mandataire - Mandat de facturation' },
        { value: 0, text: 'Commissionaire sans Stripe' },
        { value: 3, text: 'Mandataire - Facturation externe' },
    ],
    commissionaires() {
        return [CorporationBilling.STOCKLEAR, CorporationBilling.NO];
    },
    mandataires() {
        return [CorporationBilling.YES, CorporationBilling.EXTERN];
    },
});

export const CorporationPaymentAndInvoiceUploadTypes = Object.freeze({
    BEFORE: 1,
    AFTER: 2,
    labels: [
        { value: 1, text: 'Paiement AVANT upload de facture' },
        { value: 2, text: 'Paiement APRES upload de facture' },
    ],
});

export const CorporationFulfillmentTypes = Object.freeze({
    WITH_TRANSPORT: 1,
    WITHOUT_TRANSPORT: 2,
    CROSS_DOCK: 3,
    labels: [
        { value: 1, text: 'Fulfillment AVEC transport' },
        { value: 2, text: 'Fulfillment SANS transport' },
        { value: 3, text: 'Cross dock' },
    ],
});

export const CorporationBillingUploadExternalInvoiceTypes = Object.freeze({
    ORDER: 1,
    TRUCK: 2,
    labels: [
        { value: 1, text: 'To the order' },
        { value: 2, text: 'To the truck' },
    ],
});

export const CorporationAccountTypes = Object.freeze({
    SMES: 1,
    LARGE: 2,
    TOP: 3,
    labels: [
        { value: null, text: '' },
        { value: 1, text: 'Mid Compte' },
        { value: 2, text: 'Grands comptes' },
        { value: 3, text: 'Top comptes' },
    ],
    restricted() {
        return [CorporationAccountTypes.LARGE, CorporationAccountTypes.TOP];
    },
});

export const CorporationSFModes = Object.freeze({
    IMPORT: 1,
    CATALOG: 2,
    labels: [
        { value: 1, text: 'Stock' },
        { value: 2, text: 'Catalog' },
    ],
});

export const CorporationSizeOptions = Object.freeze({
    labels: [
        { label: '1-2 employés', value: '1-2' },
        { label: '3-10 employés', value: '3-10' },
        { label: '11-50 employés', value: '11-50' },
        { label: '50-250 employés', value: '50-250' },
        { label: '251-1500 employés', value: '251-1500' },
        { label: '1500 < employés', value: '1500+' },
    ],
});

export const UserRoles = Object.freeze({
    ADMIN: 1,
    BUYER: 2,
    SELLER: 3,
    COMMERCIAL: 4,
    PRODUCTION_AGENT: 5,
    ACCOUNTANT: 6,
    OPERATOR_BUYER: 7,
    OPERATOR_SAV_TRANSPORT: 8,
    LOGISTICIAN: 9,
    SELLER_ACCOUNTANT: 10,
    TRANSPORTER: 11,
    SUPPLIER: 12,
    labels: {
        1: 'Root',
        2: 'Buyer',
        3: 'Seller',
        4: 'Commercial',
        5: 'Production agent',
        6: 'Accountant',
        7: 'Operator buyer',
        8: 'Operator sav/transport',
        9: 'Logisticians',
        10: 'Accountant',
        11: 'Transporter',
        12: 'Supplier',
    },
    keys: {
        1: 'admin',
        2: 'buyer',
        3: 'seller',
        4: 'commercial',
        5: 'production_agent',
        6: 'accountant',
        7: 'operator_buyer',
        8: 'operator_sav_transport',
        9: 'logistician',
        10: 'seller_accountant',
        11: 'transporter',
        12: 'supplier',
    },
    crudAdmin() {
        return [
            UserRoles.ADMIN,
            UserRoles.ACCOUNTANT,
            UserRoles.OPERATOR_BUYER,
            UserRoles.OPERATOR_SAV_TRANSPORT,
        ];
    },
    managers() {
        return [
            UserRoles.ADMIN,
            UserRoles.PRODUCTION_AGENT,
            UserRoles.ACCOUNTANT,
            UserRoles.OPERATOR_BUYER,
            UserRoles.OPERATOR_SAV_TRANSPORT,
        ];
    },
    operatorsBuyer() {
        return [UserRoles.OPERATOR_BUYER, UserRoles.OPERATOR_SAV_TRANSPORT];
    },
    sellers() {
        return [UserRoles.SELLER, UserRoles.SELLER_ACCOUNTANT];
    },
    buyers() {
        return [UserRoles.BUYER];
    },
    transporters() {
        return [UserRoles.TRANSPORTER, UserRoles.SUPPLIER];
    },
    logisticians() {
        return [UserRoles.LOGISTICIAN, UserRoles.SUPPLIER];
    },
    suppliers() {
        // Return all unique roles that can be suppliers
        return [...new Set([...UserRoles.transporters(), ...UserRoles.logisticians()])];
    },
});

export const OfferTypes = Object.freeze({
    PURCHASE: 1,
    AUCTION: 2,
    labels: {
        1: 'Purchase',
        2: 'Auction',
    },
});

export const OfferBannerTypes = Object.freeze({
    NONE: 0,
    HALF_TRUCK: 1,
    FULL_TRUCK: 2,
    WITHOUT_RESERVE: 3,
    FLASH_SALE: 4,
    OFFER_300_TRANSPORT: 5,
    FLASH_SALE_24H: 6,
    labels: [
        { value: 0, text: 'None' },
        { value: 1, text: 'Half truck' },
        { value: 2, text: 'Full truck' },
        { value: 3, text: 'Without reserve price' },
        { value: 4, text: 'Flash sale' },
        { value: 5, text: 'Offer 300€ transport' },
        { value: 6, text: '24H Flash sale' },
    ],
});

export const OfferAccess = Object.freeze({
    FREE: 1,
    PRIVATE: 2,
    UNLISTED: 3,
    labels: {
        1: 'Access to the members',
        2: 'Private',
        3: 'Unlisted',
    },
});

export const OfferQualities = Object.freeze({
    A: 'New with original packaging',
    B: 'New without original packaging',
    C: 'Functional customer returns',
    D: 'Transport damaged',
    E: 'Refurbished A',
    F: 'Refurbished B',
    G: 'Functional used products',
    H: 'Nonfunctional',
    I: 'Not tested',
    Z: 'Missing product',
    labels: [
        { value: 'A', text: 'New with original packaging' },
        { value: 'B', text: 'New without original packaging' },
        { value: 'C', text: 'Functional customer returns' },
        { value: 'D', text: 'Transport damaged' },
        { value: 'E', text: 'Refurbished A' },
        { value: 'F', text: 'Refurbished B' },
        { value: 'G', text: 'Functional used products' },
        { value: 'H', text: 'Nonfunctional' },
        { value: 'I', text: 'Not tested' },
    ],
});

export const OfferStatus = Object.freeze({
    DRAFT: 0,
    BEING_REVIEWED: 4,
    SCHEDULED: 6,
    ACTIVE: 1,
    FINISHED: 7,
    INACTIVE: 2,
    RESTOCKING: 3,
    SOLD: 8,
    CANCELED: 9,
    RERUN: 10,
    labels: [
        { value: 0, text: 'Draft' },
        { value: 4, text: 'Being reviewed' },
        { value: 6, text: 'Will be lived' },
        { value: 1, text: 'Live' },
        { value: 7, text: 'Finished - Under validation' },
        { value: 8, text: 'Sold' },
        { value: 2, text: 'Inactive' },
        { value: 3, text: 'Restocked' },
        { value: 9, text: 'Canceled' },
        { value: 10, text: 'Rerun' },
    ],
    severity: {
        0: 'secondary',
        1: 'success',
        2: 'danger',
        3: 'danger',
        4: 'warning',
        6: 'success',
        7: 'secondary',
        8: 'secondary',
        9: 'secondary',
        10: 'secondary',
    },
    disabledForTrucks: (status) =>
        [OfferStatus.CANCELED, OfferStatus.INACTIVE, OfferStatus.RERUN].indexOf(status) !== -1,
});

export const OrderStatus = Object.freeze({
    WAITING: 0,
    VALIDATED: 1,
    CANCELED: 2,
    LOST: 3,
    BELOW_RP: 4,
    ALERT_PAYMENT: 5,
    TOO_LATE_PAYMENT: 6,
    PAID: 7,
    PREPARATION_LATE: 8,
    READY_FOR_PICK_UP: 9,
    PICKED_UP: 10,
    RECEIVED: 11,
    FINISHED: 12,
    LITIGATION: 13,
    REFUND: 14,
    REFUND_PARTIALLY: 15,
    FINISHED_WITH_LITIGATION: 16,
    CANCELED_WITH_LITIGATION: 17,
    ERROR_ORDER: 18,
    CANCEL_BID: 19,
    labels: {
        0: 'Waiting',
        1: 'Validated',
        2: 'Canceled - no show',
        3: 'Lost',
        4: 'Below reserve price',
        5: 'Alert payment',
        6: 'Too late payment',
        7: 'Paid',
        8: 'Preparation late',
        9: 'Ready for pick-up',
        10: 'Picked-Up',
        11: 'Received',
        12: 'Finished',
        13: 'Litigation in progress',
        14: 'Refund',
        15: 'Partially refund',
        16: 'Finished with litigation',
        17: 'Canceled with litigation',
        18: 'Error order',
        19: 'Cancel bid',
    },
    statusWaitingBeforeReady: (status) =>
        [
            OrderStatus.VALIDATED,
            OrderStatus.ALERT_PAYMENT,
            OrderStatus.TOO_LATE_PAYMENT,
            OrderStatus.PAID,
            OrderStatus.PREPARATION_LATE,
        ].indexOf(status),
    statusWaitingOrder: (status) =>
        [OrderStatus.VALIDATED, OrderStatus.ALERT_PAYMENT, OrderStatus.TOO_LATE_PAYMENT].indexOf(
            status
        ),
    statusFinished: (status) =>
        [OrderStatus.FINISHED, OrderStatus.FINISHED_WITH_LITIGATION].indexOf(status),
    statusBeforeDelivery: (status) =>
        [
            OrderStatus.PAID,
            OrderStatus.PREPARATION_LATE,
            OrderStatus.READY_FOR_PICK_UP,
            OrderStatus.PICKED_UP,
        ].indexOf(status),
    statusAfterDelivery: (status) =>
        [
            OrderStatus.RECEIVED,
            OrderStatus.FINISHED,
            OrderStatus.LITIGATION,
            OrderStatus.REFUND,
            OrderStatus.REFUND_PARTIALLY,
            OrderStatus.FINISHED_WITH_LITIGATION,
        ].indexOf(status),
    statusForDelivery: (status) =>
        [
            OrderStatus.PAID,
            OrderStatus.PREPARATION_LATE,
            OrderStatus.READY_FOR_PICK_UP,
            OrderStatus.PICKED_UP,
            OrderStatus.RECEIVED,
            OrderStatus.FINISHED,
            OrderStatus.LITIGATION,
            OrderStatus.REFUND,
            OrderStatus.REFUND_PARTIALLY,
            OrderStatus.FINISHED_WITH_LITIGATION,
        ].indexOf(status),
    statusAfterPaid: () => [
        OrderStatus.PAID,
        OrderStatus.PREPARATION_LATE,
        OrderStatus.READY_FOR_PICK_UP,
        OrderStatus.PICKED_UP,
        OrderStatus.RECEIVED,
        OrderStatus.FINISHED,
        OrderStatus.LITIGATION,
        OrderStatus.REFUND,
        OrderStatus.REFUND_PARTIALLY,
        OrderStatus.FINISHED_WITH_LITIGATION,
    ],
    statusCanceled: () => [
        OrderStatus.CANCELED,
        OrderStatus.BELOW_RP,
        OrderStatus.CANCELED_WITH_LITIGATION,
        OrderStatus.ERROR_ORDER,
        OrderStatus.CANCEL_BID,
    ],
    trackingKeys: () => {
        return {
            waiting: 'Awaiting payment',
            to_prepare: 'To prepare',
            to_pick_up: 'To be shipped / to be picked-up',
            picked_up: 'Delivered / picked-up',
            litigation: 'In litigation',
            finished: 'Finished',
        };
    },
    severity: {
        0: 'primary',
        1: 'success',
        2: 'danger',
        3: 'primary',
        4: 'primary',
        5: 'warning',
        6: 'danger',
        7: 'primary',
        8: 'primary',
        9: 'primary',
        10: 'primary',
        11: 'primary',
        12: 'success',
        13: 'primary',
        14: 'success',
        15: 'success',
        16: 'success',
        17: 'primary',
        19: 'danger',
    },
});

export const OrderTypes = Object.freeze({
    PROPOSAL: 1,
    AUCTION: 2,
    ORDER: 3,
    labels: {
        1: 'Proposal',
        2: 'Auction',
        3: 'Order',
    },
});

export const CorporationAccessStatus = Object.freeze({
    WAITING: 1,
    ACCEPTED: 2,
    REFUSED: 3,
    labels: {
        1: 'Waiting',
        2: 'Accepted',
        3: 'Refused',
    },
});

export const CorporationVatStatus = Object.freeze({
    NOT_CHECKED: 0,
    EMPTY: 1,
    VALID: 2,
    INVALID: 3,
    UNREACHABLE_API: 4,
    VALID_LOCAL: 5,
    labels: [
        { value: 0, text: 'Not checked' },
        { value: 1, text: 'Empty' },
        { value: 2, text: 'Valid EU' },
        { value: 3, text: 'Invalid' },
        { value: 4, text: 'Unreachable API' },
        { value: 5, text: 'Valid local' },
    ],
    statusValid: (status) =>
        [CorporationVatStatus.VALID, CorporationVatStatus.VALID_LOCAL].indexOf(status) !== -1,
});

export const CorporationTypes = Object.freeze({
    SELLER: 1,
    BUYER: 2,
    labels: [
        { value: 1, text: 'Seller' },
        { value: 2, text: 'Buyer' },
    ],
});

export const OptionInsights = Object.freeze({
    labels: [
        { value: 'quality', text: 'Quality' },
        { value: 'size', text: 'Size' },
        { value: 'color', text: 'Color' },
        { value: 'brand', text: 'Brand' },
        { value: 'subcategory', text: 'Subcategory' },
        { value: 'category', text: 'Category' },
        { value: 'gender', text: 'Gender' },
        { value: 'weight', text: 'Weight' },
    ],
});

export const InvoiceStatus = Object.freeze({
    CREATED: 1,
    SENT: 2,
    PAID: 3,
    CANCELED: 4,
    labels: {
        1: 'Created',
        2: 'Sent',
        3: 'Paid',
        4: 'Canceled',
    },
    severity: {
        1: 'primary',
        2: 'warning',
        3: 'success',
        4: 'danger',
    },
    editable() {
        return {
            1: InvoiceStatus.labels[1],
            2: InvoiceStatus.labels[2],
            4: InvoiceStatus.labels[4],
        };
    },
});

export const TransportStatus = Object.freeze({
    DRAFT: 7,
    WAITING: 1,
    PENDING_PAYMENT: 2,
    TOO_LATE_PAYMENT: 3,
    PAID: 4,
    REFUSED: 5,
    CANCELED: 6,
    labels: {
        1: 'Waiting',
        2: 'Pending payment',
        3: 'Too late payment',
        4: 'Paid',
        5: 'Refused',
        6: 'Canceled',
        7: 'Draft',
    },
    badges: {
        1: 'badge-secondary',
        2: 'badge-warning',
        3: 'badge-warning',
        4: 'badge-success',
        5: 'badge-danger',
        6: 'badge-danger',
        7: 'badge-secondary',
    },
    severity: {
        1: 'primary',
        2: 'warning',
        3: 'warning',
        4: 'success',
        5: 'danger',
        6: 'danger',
        7: 'secondary',
    },
    waitingPayment: (status) =>
        [
            TransportStatus.WAITING,
            TransportStatus.PENDING_PAYMENT,
            TransportStatus.TOO_LATE_PAYMENT,
        ].indexOf(status) !== -1,
});

// Liste des types possibles pour les messages pré-enregistrés.
export const UserMessageVisibility = Object.freeze({
    1: 'seller',
    2: 'buyer',
    9: 'logistician',
});

export const LitigationStatus = Object.freeze({
    // En attente de déclaration du litige par l'acheteur
    PENDING_DECLARATION: 0,
    // En attente de qualification du litige par le sav
    PENDING_QUALIFICATION: 1,
    // En attente de validation par le vendeur
    PENDING_VALIDATION: 2,
    // Validé par le vendeur, en attente de traitement par le sav
    VALIDATED: 3,
    // En attente de remboursement (validé par le SAV)
    PENDING_REFUND: 4,
    // Litige terminée avec remboursement
    REFUNDED: 5,
    // Demande refusée
    REFUSED: 6,
    // Demande annulée
    CANCELED: 7,

    labels: [
        { value: 0, text: 'litigation_status.pending_declaration.title' },
        { value: 1, text: 'litigation_status.pending_qualification.title' },
        { value: 2, text: 'litigation_status.pending_validation.title' },
        { value: 3, text: 'litigation_status.validated.title' },
        { value: 4, text: 'litigation_status.pending_refund.title' },
        { value: 5, text: 'litigation_status.refunded.title' },
        { value: 6, text: 'litigation_status.refused.title' },
        { value: 7, text: 'litigation_status.canceled.title' },
    ],

    valueToTitle: {
        0: 'litigation_status.pending_declaration.title',
        1: 'litigation_status.pending_qualification.title',
        2: 'litigation_status.pending_validation.title',
        3: 'litigation_status.validated.title',
        4: 'litigation_status.pending_refund.title',
        5: 'litigation_status.refunded.title',
        6: 'litigation_status.refused.title',
        7: 'litigation_status.canceled.title',
        999: 'litigation_status.pending_qualification.title',
    },

    valueToHistory: {
        0: 'litigation_status.pending_declaration.history',
        1: 'litigation_status.pending_qualification.history',
        2: 'litigation_status.pending_validation.history',
        3: 'litigation_status.validated.history',
        4: 'litigation_status.pending_refund.history',
        5: 'litigation_status.refunded.history',
        6: 'litigation_status.refused.history',
        7: 'litigation_status.canceled.history',
        999: 'litigation_status.pending_qualification.history_back',
    },

    badges: {
        0: 'badge-secondary',
        1: 'badge-warning',
        2: 'badge-info',
        3: 'badge-warning',
        4: 'badge-warning',
        5: 'badge-success',
        6: 'badge-danger',
        7: 'badge-danger',
        999: 'badge-warning',
    },
});

export const LitigationProductStatus = Object.freeze({
    // En attente de qualification
    PENDING_QUALIFICATION: 0,
    // Produit qualifié
    QUALIFIED: 1,
    // Remboursable
    REFUNDABLE: 2,
    // Non remboursable
    NOT_REFUNDABLE: 3,

    labels: [
        { value: 0, text: 'Pending qualification' },
        { value: 1, text: 'Qualified' },
        { value: 2, text: 'Refundable' },
        { value: 3, text: 'Not refundable' },
    ],

    valueToLabel: {
        0: 'Pending qualification',
        1: 'Qualified',
        2: 'Refundable',
        3: 'Not refundable',
    },
});

export const LitigationProductProblemType = Object.freeze({
    // Produit manquant
    MISSING_PRODUCT: 0,
    // Pièce manquante
    MISSING_PART: 1,
    // Produit non fonctionnel
    NOT_WORKING: 2,
    // Erreur sur la qualité
    QUALITY_ERROR: 3,
    // Accessoire produit manquant
    MISSING_ACCESSORY: 4,
    // Erreur de référence
    REFERENCE_ERROR: 5,

    labels: [
        { value: 0, text: 'Missing product' },
        { value: 1, text: 'Missing part' },
        { value: 2, text: 'Not working' },
        { value: 3, text: 'Quality error' },
        { value: 4, text: 'Missing accessory' },
        { value: 5, text: 'Reference error' },
    ],

    valueToLabel: {
        0: 'Missing product',
        1: 'Missing part',
        2: 'Not working',
        3: 'Quality error',
        4: 'Missing accessory',
        5: 'Reference error',
    },
});

export const StripeReportStatus = Object.freeze({
    IN_PROGRESS: 0,
    SUCCESS: 1,
    ERROR: 2,
    WARNING: 3,
    labels: [
        { value: 0, text: 'In progress' },
        { value: 1, text: 'Success' },
        { value: 2, text: 'Error' },
        { value: 3, text: 'Verified' },
        { value: 4, text: 'Warning' },
    ],
    badges: {
        0: 'badge-primary',
        1: 'badge-success',
        2: 'badge-danger',
        3: 'badge-warning',
    },
});

export const InvoiceTemplate = Object.freeze({
    CLASSIC: 'classic',
    ORDER: 'order',
    CREDIT: 'credit',
    COMMISSION: 'commission',
    TRANSPORT: 'transport',
    ERROR_PREPA: 'error_prepa',
    FULFILLMENT: 'commission_buyer',
    COMMISSION_BUYER: 'fulfillment',
    NOSHOW: 'noshow',
    LOGISTIC_BUYER: 'logistic_buyer',
    chargeable: ['classic', 'order', 'transport', 'noshow', 'logistic_buyer'],
});

export const BannerStatus = Object.freeze({
    DRAFT: 0,
    ONLINE: 1,
    DISABLED: 2,
    labels: [
        { value: 0, text: 'Brouillon' },
        { value: 1, text: 'En ligne' },
        { value: 2, text: 'Désactivé' },
    ],
});

export const CheckoutStatus = Object.freeze({
    CREATED: 0,
    PAYMENT: 1,
    PAID: 2,
    ABANDONNED: 3,
    labels: [
        { value: 0, text: 'CREATED' },
        { value: 1, text: 'PAYMENT' },
        { value: 2, text: 'PAID' },
        { value: 3, text: 'ABANDONNED' },
    ],
});

export const ProductImportStatus = Object.freeze({
    WAITING: 0,
    FINISH: 1,
    SKIPPED: 2,
    REVERTED: 3,
    RUNNING: 4,
    ERROR: 5,
    labels: [
        { value: 0, text: 'Waiting' },
        { value: 1, text: 'Finish' },
        { value: 2, text: 'Skipped' },
        { value: 3, text: 'Reverted' },
        { value: 4, text: 'Running' },
        { value: 5, text: 'Error' },
    ],
});

export const PreferencesNotificationNewsletter = Object.freeze({
    ALL: 'ALL',
    THREE_TIMES: '3D',
    ONE_TIME: '1D',
    labels: [
        { value: 'ALL', text: 'All' },
        { value: '1D', text: '1 times' },
        { value: '3D', text: '3 times' },
    ],
});

export const MarketingDirect = Object.freeze({
    SPECIAL_OPERATIONS: 256,
    ANNOUCEMENT_NEW_SELLER: 257,
});

export const NewsletterStatus = Object.freeze({
    DRAFT: 0,
    SYNCHRONIZED: 1,
    labels: {
        0: 'Draft',
        1: 'Synchronized',
    },
    badges: {
        0: 'badge-warning',
        1: 'badge-success',
    },
    severity: {
        0: 'warning',
        1: 'success',
    },
});

export const AIMessageFeedback = Object.freeze({
    None: 0,
    LIKE: 1,
    DISLIKE: 2,
});

export const CorporationHubspotStatus = Object.freeze({
    WAITING_INFO: 'waiting_info',
    WAITING: 'waiting',
    MEMBRE: 'membre',
    PRIMO_ENCHERISSEUR: 'primo-encherisseur',
    PRIMO_PERDANT: 'primo-perdant',
    ENCHERISSEUR: 'encherisseur',
    PRIMO_ACHETEUR: 'primo-acheteur',
    ACHETEUR: 'acheteur',
    RENCHERISSEUR: 'rencherisseur',
    MEMBRE_ACCEPTE: 'Membre accepté',
    REACHETEUR: 'Réacheteur',
    NOSHOW: 'Noshow',
    REFUSED: 'Refusé',
    labels: [
        { value: 0, text: 'Waiting information' },
        { value: 1, text: 'Waiting' },
        { value: 9, text: 'Membre' },
        { value: 12, text: 'Primo-encherisseur' },
        { value: 4, text: 'Primo-perdant' },
        { value: 11, text: 'Encherisseur' },
        { value: 6, text: 'Primo-acheteur' },
        { value: 7, text: 'Acheteur' },
        { value: 8, text: 'Renchérisseur' },
        { value: 2, text: 'Membre accepté' },
        { value: 10, text: 'Réacheteur' },
        { value: 5, text: 'Noshow' },
        { value: 3, text: 'Refusé' },
    ],
});

export const SegmentStatus = Object.freeze({
    DRAFT: 0,
    SYNCHRONIZED: 1,
    labels: {
        0: 'Draft',
        1: 'Synchronized',
    },
    badges: {
        0: 'badge-warning',
        1: 'badge-success',
    },
    severity: {
        0: 'warning',
        1: 'success',
    },
});

export const CorporationBillingUploadExternalInvoiceTypesUpload = Object.freeze({
    SEPARATE: 1,
    COMBINED: 2,
    labels: [
        { value: 1, text: 'Separate order and credit invoices (2 documents)' },
        { value: 2, text: 'Combined order and credit invoices (2 lines)' },
    ],
});

export const Countries = Object.freeze({
    FRANCE: 4,
    labels: {
        iso: {
            4: 'FR',
        },
    },
    4: {
        name: 'France',
        iso: 'FR',
        reg_no_length: 14,
        api: {
            siret: 'v1/partners/siren/siret/detail',
            /**
             * Transforme les valeurs nulles ou ND récupèrées de l'api siren par une chaîne vide
             *
             * @param data
             * @returns {*}
             */
            transformValueIfEmpty: (data) => {
                for (const [key, value] of Object.entries(data)) {
                    if (value === null) {
                        data[key] = '';
                    }

                    if (value === '[ND]') {
                        data[key] = '';
                    }
                }

                return data;
            },
        },

        /**
         * Calcul du numéro de TVA pour a France
         *
         * @param siren
         */
        calculTva: (siren) => {
            // Formule pour le numéro de TVA intracommunautaire ou non
            // (12 + 3 * (SIREN mod 97)) mod 97
            const code = (12 + 3 * (siren % 97)) % 97;

            return `${Countries.labels.iso[Countries.FRANCE]}${code}${siren}`;
        },
    },
});

export const SellerRestrictionAddress = Object.freeze({
    BILLING: 'BILLING',
    DELIVERY: 'DELIVERY',
});

export const ConditionType = Object.freeze({
    OFFER: 'offer',
    SELLER: 'seller',
});

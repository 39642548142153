import { defineStore } from 'pinia'

export const useToastStore = defineStore('toast', {
    persist: true,

    state: () => ({
        toasts: [],
    }),

    actions: {
        // Add a toast to the list
        push(toast) {
            this.toasts.push(toast);
        },

        // Reset list of toasts
        reset() {
            this.toasts = [];
        },
    },
})

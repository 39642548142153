import Tooltip from 'primevue/tooltip';

export default defineNuxtPlugin((nuxtApp) => {
    //Primevue tooltip directive not working on mobile so need to custom the directive
    const handleTooltip = (el, bind, component) => {
        //If mobile or tablet, we need to add the focus modifier to the directive and the tabindex attribute to the element
        if (nuxtApp.$device.isMobileOrTablet) {
            const tempModifier = structuredClone(bind.modifiers);
            tempModifier.focus = true;
            bind.modifiers = tempModifier;
            el.setAttribute('tabindex', 0);
        }

        //Add the directive to the element
        bind.dir = Tooltip;

        for (const key in component.dirs) {
            if (component.dirs[key].value === bind.value) {
                component.dirs[key] = bind;
            }
        }

        return {
            el, bind, component
        }
    }

    nuxtApp.vueApp.directive('tooltip', {
        created: (el, bind, component) => {
            const tooltip = handleTooltip(el, bind, component);
            bind.dir.created(tooltip.el, tooltip.bind, tooltip.component);
        },

        updated: (el, bind, component) => {
            const tooltip = handleTooltip(el, bind, component);
            bind.dir.updated(tooltip.el, tooltip.bind, tooltip.component);
        }
    })
})

export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();

    // if (to.fullPath !== '/maintenance') {
    //     return await navigateTo(nuxtApp.$localePath('maintenance'))
    // }
    if (to.fullPath === '/maintenance') {
        return await navigateTo(nuxtApp.$localePath('index'))
    }

    await nuxtApp.$me.refresh();
})

import { default as indexgokIAkGOvkMeta } from "/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue?macro=true";
import { default as indexdRXZvyWs6wMeta } from "/builds/stocklear/nuxt-frontend/pages/account/index.vue?macro=true";
import { default as indexhY0NrfUATiMeta } from "/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue?macro=true";
import { default as indexQn2AihKSWoMeta } from "/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue?macro=true";
import { default as indexL0brafN1vzMeta } from "/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue?macro=true";
import { default as lostL1v5OAVOBFMeta } from "/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue?macro=true";
import { default as waitingfGRSmOSNVLMeta } from "/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue?macro=true";
import { default as won5CPkStHuX0Meta } from "/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue?macro=true";
import { default as index0IuB2ylHkeMeta } from "/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue?macro=true";
import { default as waitingJMzAt7zVHIMeta } from "/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue?macro=true";
import { default as _91_91id_93_93HsA4n9PeGiMeta } from "/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue?macro=true";
import { default as corporationGWogMTp0LNMeta } from "/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue?macro=true";
import { default as notificationsfL8BAjIcPBMeta } from "/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue?macro=true";
import { default as indexywmQ1y01lqMeta } from "/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue?macro=true";
import { default as paymentlgskUpRreCMeta } from "/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue?macro=true";
import { default as _91id_93wvOO5q9yGrMeta } from "/builds/stocklear/nuxt-frontend/pages/countries/[id].vue?macro=true";
import { default as indexDv5Y6F2u1gMeta } from "/builds/stocklear/nuxt-frontend/pages/countries/index.vue?macro=true";
import { default as _91id_93pZpiCFzy5uMeta } from "/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue?macro=true";
import { default as _91id_937elxxhnuKwMeta } from "/builds/stocklear/nuxt-frontend/pages/fees/[id].vue?macro=true";
import { default as indexA3gGFStNcZMeta } from "/builds/stocklear/nuxt-frontend/pages/fees/index.vue?macro=true";
import { default as forgot_45password9VgNxRmZeIMeta } from "/builds/stocklear/nuxt-frontend/pages/forgot-password.vue?macro=true";
import { default as indexBjWfgYNw7NMeta } from "/builds/stocklear/nuxt-frontend/pages/index.vue?macro=true";
import { default as billingZttofisN7LMeta } from "/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue?macro=true";
import { default as creditz3l564pp3DMeta } from "/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue?macro=true";
import { default as stocklearC8mTrlu2i3Meta } from "/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue?macro=true";
import { default as _91id_93euy4Skj7axMeta } from "/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue?macro=true";
import { default as indexYeloGMYCVsMeta } from "/builds/stocklear/nuxt-frontend/pages/litigations/index.vue?macro=true";
import { default as indexFiOYC0RAW1Meta } from "/builds/stocklear/nuxt-frontend/pages/login/index.vue?macro=true";
import { default as redirectg8S5MhpWLhMeta } from "/builds/stocklear/nuxt-frontend/pages/login/redirect.vue?macro=true";
import { default as maintenancefrTF78mf8tMeta } from "/builds/stocklear/nuxt-frontend/pages/maintenance.vue?macro=true";
import { default as _91id_93oqCM6KhhsTMeta } from "/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue?macro=true";
import { default as indexhVN4sFRWuWMeta } from "/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue?macro=true";
import { default as _91id_93v6IrNed6ZSMeta } from "/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue?macro=true";
import { default as indexJJxIyv858uMeta } from "/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue?macro=true";
import { default as _91id_93trbMYtl21hMeta } from "/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue?macro=true";
import { default as indexVtY8Ren6vqMeta } from "/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue?macro=true";
import { default as redirect8LIbVNj9yqMeta } from "/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue?macro=true";
import { default as _91id_93pUD12kVspSMeta } from "/builds/stocklear/nuxt-frontend/pages/messages/[id].vue?macro=true";
import { default as indexRsx4yR7ZI3Meta } from "/builds/stocklear/nuxt-frontend/pages/messages/index.vue?macro=true";
import { default as indexi7XeucDW0LMeta } from "/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue?macro=true";
import { default as indexfhIQvhMUtEMeta } from "/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue?macro=true";
import { default as step1Yu9inYaYpEMeta } from "/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue?macro=true";
import { default as step2hkgDMs4EOyMeta } from "/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue?macro=true";
import { default as step3zost7V6CJUMeta } from "/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue?macro=true";
import { default as compositionfTOtduk28NMeta } from "/builds/stocklear/nuxt-frontend/pages/offers/composition.vue?macro=true";
import { default as indexoVV5oinc0CMeta } from "/builds/stocklear/nuxt-frontend/pages/offers/index.vue?macro=true";
import { default as indexRF0BYF2ESaMeta } from "/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue?macro=true";
import { default as indexqCGSkPO5zeMeta } from "/builds/stocklear/nuxt-frontend/pages/orders/index.vue?macro=true";
import { default as _91_91id_93_933ppJWzAcqdMeta } from "/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue?macro=true";
import { default as pingKYMPMBytMNMeta } from "/builds/stocklear/nuxt-frontend/pages/ping.vue?macro=true";
import { default as createXUYkNV8OxPMeta } from "/builds/stocklear/nuxt-frontend/pages/products/create.vue?macro=true";
import { default as _91id_93sjQfYtu0sdMeta } from "/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue?macro=true";
import { default as excelLruRM5BpaJMeta } from "/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue?macro=true";
import { default as index0KlAPorZLWMeta } from "/builds/stocklear/nuxt-frontend/pages/products/import/index.vue?macro=true";
import { default as truckn6DgAGvGdxMeta } from "/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue?macro=true";
import { default as _91id_93A6UUPmY7NuMeta } from "/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue?macro=true";
import { default as indexcXacvPhL8AMeta } from "/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue?macro=true";
import { default as activityJf3L7sB32IMeta } from "/builds/stocklear/nuxt-frontend/pages/register/activity.vue?macro=true";
import { default as buyer41hHlxjMx3Meta } from "/builds/stocklear/nuxt-frontend/pages/register/buyer.vue?macro=true";
import { default as confirmationHphV9eNMECMeta } from "/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue?macro=true";
import { default as indexEm5SwEduFtMeta } from "/builds/stocklear/nuxt-frontend/pages/register/index.vue?macro=true";
import { default as profileCEPDV7cwLAMeta } from "/builds/stocklear/nuxt-frontend/pages/register/profile.vue?macro=true";
import { default as activityRPkLmtpFP8Meta } from "/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue?macro=true";
import { default as goodbyebXJ43ZCj1NMeta } from "/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue?macro=true";
import { default as indexbK29KU1H33Meta } from "/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue?macro=true";
import { default as _91token_93l9aVrz6sJeMeta } from "/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue?macro=true";
import { default as addJF1eWoU6zmMeta } from "/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue?macro=true";
import { default as indexV73dpxaTLDMeta } from "/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue?macro=true";
import { default as _91id_939xM9ZPL6W1Meta } from "/builds/stocklear/nuxt-frontend/pages/transports/[id].vue?macro=true";
import { default as indexbr2g73UJOrMeta } from "/builds/stocklear/nuxt-frontend/pages/transports/index.vue?macro=true";
import { default as _91id_933FHwJSEc5WMeta } from "/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue?macro=true";
import { default as indexR6GESbVflXMeta } from "/builds/stocklear/nuxt-frontend/pages/trucks/index.vue?macro=true";
import { default as versionSyB5EEZ5u7Meta } from "/builds/stocklear/nuxt-frontend/pages/version.vue?macro=true";
import { default as component_45stub3nsAySkTXqMeta } from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub3nsAySkTXq } from "/builds/stocklear/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-addresses___en",
    path: "/account/addresses",
    meta: indexgokIAkGOvkMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___uk",
    path: "/account/addresses",
    meta: indexgokIAkGOvkMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___fr",
    path: "/account/addresses",
    meta: indexgokIAkGOvkMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___de",
    path: "/account/addresses",
    meta: indexgokIAkGOvkMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___es",
    path: "/account/addresses",
    meta: indexgokIAkGOvkMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___nl",
    path: "/account/addresses",
    meta: indexgokIAkGOvkMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/account",
    meta: indexdRXZvyWs6wMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___uk",
    path: "/account",
    meta: indexdRXZvyWs6wMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr",
    path: "/account",
    meta: indexdRXZvyWs6wMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/account",
    meta: indexdRXZvyWs6wMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/account",
    meta: indexdRXZvyWs6wMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl",
    path: "/account",
    meta: indexdRXZvyWs6wMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___en",
    path: "/account/invoices",
    meta: indexhY0NrfUATiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___uk",
    path: "/account/invoices",
    meta: indexhY0NrfUATiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___fr",
    path: "/account/invoices",
    meta: indexhY0NrfUATiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___de",
    path: "/account/invoices",
    meta: indexhY0NrfUATiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___es",
    path: "/account/invoices",
    meta: indexhY0NrfUATiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___nl",
    path: "/account/invoices",
    meta: indexhY0NrfUATiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "account-messages___en",
    path: "/account/messages",
    meta: indexQn2AihKSWoMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-messages___uk",
    path: "/account/messages",
    meta: indexQn2AihKSWoMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-messages___fr",
    path: "/account/messages",
    meta: indexQn2AihKSWoMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-messages___de",
    path: "/account/messages",
    meta: indexQn2AihKSWoMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-messages___es",
    path: "/account/messages",
    meta: indexQn2AihKSWoMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-messages___nl",
    path: "/account/messages",
    meta: indexQn2AihKSWoMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers___en",
    path: "/account/offers",
    meta: indexL0brafN1vzMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers___uk",
    path: "/account/offers",
    meta: indexL0brafN1vzMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers___fr",
    path: "/account/offers",
    meta: indexL0brafN1vzMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers___de",
    path: "/account/offers",
    meta: indexL0brafN1vzMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers___es",
    path: "/account/offers",
    meta: indexL0brafN1vzMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers___nl",
    path: "/account/offers",
    meta: indexL0brafN1vzMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-lost___en",
    path: "/account/offers/lost",
    meta: lostL1v5OAVOBFMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-lost___uk",
    path: "/account/offers/lost",
    meta: lostL1v5OAVOBFMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-lost___fr",
    path: "/account/offers/lost",
    meta: lostL1v5OAVOBFMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-lost___de",
    path: "/account/offers/lost",
    meta: lostL1v5OAVOBFMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-lost___es",
    path: "/account/offers/lost",
    meta: lostL1v5OAVOBFMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-lost___nl",
    path: "/account/offers/lost",
    meta: lostL1v5OAVOBFMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/lost.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-waiting___en",
    path: "/account/offers/waiting",
    meta: waitingfGRSmOSNVLMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-waiting___uk",
    path: "/account/offers/waiting",
    meta: waitingfGRSmOSNVLMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-waiting___fr",
    path: "/account/offers/waiting",
    meta: waitingfGRSmOSNVLMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-waiting___de",
    path: "/account/offers/waiting",
    meta: waitingfGRSmOSNVLMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-waiting___es",
    path: "/account/offers/waiting",
    meta: waitingfGRSmOSNVLMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-waiting___nl",
    path: "/account/offers/waiting",
    meta: waitingfGRSmOSNVLMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-won___en",
    path: "/account/offers/won",
    meta: won5CPkStHuX0Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-won___uk",
    path: "/account/offers/won",
    meta: won5CPkStHuX0Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-won___fr",
    path: "/account/offers/won",
    meta: won5CPkStHuX0Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-won___de",
    path: "/account/offers/won",
    meta: won5CPkStHuX0Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-won___es",
    path: "/account/offers/won",
    meta: won5CPkStHuX0Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue").then(m => m.default || m)
  },
  {
    name: "account-offers-won___nl",
    path: "/account/offers/won",
    meta: won5CPkStHuX0Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/offers/won.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "/account/orders",
    meta: index0IuB2ylHkeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___uk",
    path: "/account/orders",
    meta: index0IuB2ylHkeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___fr",
    path: "/account/orders",
    meta: index0IuB2ylHkeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___de",
    path: "/account/orders",
    meta: index0IuB2ylHkeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___es",
    path: "/account/orders",
    meta: index0IuB2ylHkeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___nl",
    path: "/account/orders",
    meta: index0IuB2ylHkeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-waiting___en",
    path: "/account/orders/waiting",
    meta: waitingJMzAt7zVHIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-waiting___uk",
    path: "/account/orders/waiting",
    meta: waitingJMzAt7zVHIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-waiting___fr",
    path: "/account/orders/waiting",
    meta: waitingJMzAt7zVHIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-waiting___de",
    path: "/account/orders/waiting",
    meta: waitingJMzAt7zVHIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-waiting___es",
    path: "/account/orders/waiting",
    meta: waitingJMzAt7zVHIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-waiting___nl",
    path: "/account/orders/waiting",
    meta: waitingJMzAt7zVHIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/orders/waiting.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-id___en",
    path: "/account/profile/:id?",
    meta: _91_91id_93_93HsA4n9PeGiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "account-profile-id___uk",
    path: "/account/profile/:id?",
    meta: _91_91id_93_93HsA4n9PeGiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "account-profile-id___fr",
    path: "/account/profile/:id?",
    meta: _91_91id_93_93HsA4n9PeGiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "account-profile-id___de",
    path: "/account/profile/:id?",
    meta: _91_91id_93_93HsA4n9PeGiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "account-profile-id___es",
    path: "/account/profile/:id?",
    meta: _91_91id_93_93HsA4n9PeGiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "account-profile-id___nl",
    path: "/account/profile/:id?",
    meta: _91_91id_93_93HsA4n9PeGiMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "account-profile-corporation___en",
    path: "/account/profile/corporation",
    meta: corporationGWogMTp0LNMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-corporation___uk",
    path: "/account/profile/corporation",
    meta: corporationGWogMTp0LNMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-corporation___fr",
    path: "/account/profile/corporation",
    meta: corporationGWogMTp0LNMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-corporation___de",
    path: "/account/profile/corporation",
    meta: corporationGWogMTp0LNMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-corporation___es",
    path: "/account/profile/corporation",
    meta: corporationGWogMTp0LNMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-corporation___nl",
    path: "/account/profile/corporation",
    meta: corporationGWogMTp0LNMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/corporation.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-notifications___en",
    path: "/account/profile/notifications",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-notifications___uk",
    path: "/account/profile/notifications",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-notifications___fr",
    path: "/account/profile/notifications",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-notifications___de",
    path: "/account/profile/notifications",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-notifications___es",
    path: "/account/profile/notifications",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-profile-notifications___nl",
    path: "/account/profile/notifications",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/account/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id___en",
    path: "/checkout/:id()",
    meta: indexywmQ1y01lqMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id___uk",
    path: "/checkout/:id()",
    meta: indexywmQ1y01lqMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id___fr",
    path: "/checkout/:id()",
    meta: indexywmQ1y01lqMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id___de",
    path: "/checkout/:id()",
    meta: indexywmQ1y01lqMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id___es",
    path: "/checkout/:id()",
    meta: indexywmQ1y01lqMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id___nl",
    path: "/checkout/:id()",
    meta: indexywmQ1y01lqMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-payment___en",
    path: "/checkout/:id()/payment",
    meta: paymentlgskUpRreCMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-payment___uk",
    path: "/checkout/:id()/payment",
    meta: paymentlgskUpRreCMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-payment___fr",
    path: "/checkout/:id()/payment",
    meta: paymentlgskUpRreCMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-payment___de",
    path: "/checkout/:id()/payment",
    meta: paymentlgskUpRreCMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-payment___es",
    path: "/checkout/:id()/payment",
    meta: paymentlgskUpRreCMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-payment___nl",
    path: "/checkout/:id()/payment",
    meta: paymentlgskUpRreCMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/checkout/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "countries-id___en",
    path: "/countries/:id()",
    meta: _91id_93wvOO5q9yGrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/[id].vue").then(m => m.default || m)
  },
  {
    name: "countries-id___uk",
    path: "/countries/:id()",
    meta: _91id_93wvOO5q9yGrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/[id].vue").then(m => m.default || m)
  },
  {
    name: "countries-id___fr",
    path: "/countries/:id()",
    meta: _91id_93wvOO5q9yGrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/[id].vue").then(m => m.default || m)
  },
  {
    name: "countries-id___de",
    path: "/countries/:id()",
    meta: _91id_93wvOO5q9yGrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/[id].vue").then(m => m.default || m)
  },
  {
    name: "countries-id___es",
    path: "/countries/:id()",
    meta: _91id_93wvOO5q9yGrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/[id].vue").then(m => m.default || m)
  },
  {
    name: "countries-id___nl",
    path: "/countries/:id()",
    meta: _91id_93wvOO5q9yGrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/[id].vue").then(m => m.default || m)
  },
  {
    name: "countries___en",
    path: "/countries",
    meta: indexDv5Y6F2u1gMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/index.vue").then(m => m.default || m)
  },
  {
    name: "countries___uk",
    path: "/countries",
    meta: indexDv5Y6F2u1gMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/index.vue").then(m => m.default || m)
  },
  {
    name: "countries___fr",
    path: "/countries",
    meta: indexDv5Y6F2u1gMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/index.vue").then(m => m.default || m)
  },
  {
    name: "countries___de",
    path: "/countries",
    meta: indexDv5Y6F2u1gMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/index.vue").then(m => m.default || m)
  },
  {
    name: "countries___es",
    path: "/countries",
    meta: indexDv5Y6F2u1gMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/index.vue").then(m => m.default || m)
  },
  {
    name: "countries___nl",
    path: "/countries",
    meta: indexDv5Y6F2u1gMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/countries/index.vue").then(m => m.default || m)
  },
  {
    name: "deliveries-id___en",
    path: "/deliveries/:id()",
    meta: _91id_93pZpiCFzy5uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue").then(m => m.default || m)
  },
  {
    name: "deliveries-id___uk",
    path: "/deliveries/:id()",
    meta: _91id_93pZpiCFzy5uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue").then(m => m.default || m)
  },
  {
    name: "deliveries-id___fr",
    path: "/deliveries/:id()",
    meta: _91id_93pZpiCFzy5uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue").then(m => m.default || m)
  },
  {
    name: "deliveries-id___de",
    path: "/deliveries/:id()",
    meta: _91id_93pZpiCFzy5uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue").then(m => m.default || m)
  },
  {
    name: "deliveries-id___es",
    path: "/deliveries/:id()",
    meta: _91id_93pZpiCFzy5uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue").then(m => m.default || m)
  },
  {
    name: "deliveries-id___nl",
    path: "/deliveries/:id()",
    meta: _91id_93pZpiCFzy5uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/deliveries/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees-id___en",
    path: "/fees/:id()",
    meta: _91id_937elxxhnuKwMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees-id___uk",
    path: "/fees/:id()",
    meta: _91id_937elxxhnuKwMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees-id___fr",
    path: "/fees/:id()",
    meta: _91id_937elxxhnuKwMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees-id___de",
    path: "/fees/:id()",
    meta: _91id_937elxxhnuKwMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees-id___es",
    path: "/fees/:id()",
    meta: _91id_937elxxhnuKwMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees-id___nl",
    path: "/fees/:id()",
    meta: _91id_937elxxhnuKwMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/[id].vue").then(m => m.default || m)
  },
  {
    name: "fees___en",
    path: "/fees",
    meta: indexA3gGFStNcZMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: "fees___uk",
    path: "/fees",
    meta: indexA3gGFStNcZMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: "fees___fr",
    path: "/fees",
    meta: indexA3gGFStNcZMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: "fees___de",
    path: "/fees",
    meta: indexA3gGFStNcZMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: "fees___es",
    path: "/fees",
    meta: indexA3gGFStNcZMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: "fees___nl",
    path: "/fees",
    meta: indexA3gGFStNcZMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/fees/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45password9VgNxRmZeIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___uk",
    path: "/forgot-password",
    meta: forgot_45password9VgNxRmZeIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___fr",
    path: "/forgot-password",
    meta: forgot_45password9VgNxRmZeIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___de",
    path: "/forgot-password",
    meta: forgot_45password9VgNxRmZeIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___es",
    path: "/forgot-password",
    meta: forgot_45password9VgNxRmZeIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___nl",
    path: "/forgot-password",
    meta: forgot_45password9VgNxRmZeIMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-billing___en",
    path: "/invoices/seller/billing",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-billing___uk",
    path: "/invoices/seller/billing",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-billing___fr",
    path: "/invoices/seller/billing",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-billing___de",
    path: "/invoices/seller/billing",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-billing___es",
    path: "/invoices/seller/billing",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-billing___nl",
    path: "/invoices/seller/billing",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/billing.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-credit___en",
    path: "/invoices/seller/credit",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-credit___uk",
    path: "/invoices/seller/credit",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-credit___fr",
    path: "/invoices/seller/credit",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-credit___de",
    path: "/invoices/seller/credit",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-credit___es",
    path: "/invoices/seller/credit",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-credit___nl",
    path: "/invoices/seller/credit",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/credit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-stocklear___en",
    path: "/invoices/seller/stocklear",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-stocklear___uk",
    path: "/invoices/seller/stocklear",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-stocklear___fr",
    path: "/invoices/seller/stocklear",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-stocklear___de",
    path: "/invoices/seller/stocklear",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-stocklear___es",
    path: "/invoices/seller/stocklear",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue").then(m => m.default || m)
  },
  {
    name: "invoices-seller-stocklear___nl",
    path: "/invoices/seller/stocklear",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/invoices/seller/stocklear.vue").then(m => m.default || m)
  },
  {
    name: "litigations-id___en",
    path: "/litigations/:id()",
    meta: _91id_93euy4Skj7axMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue").then(m => m.default || m)
  },
  {
    name: "litigations-id___uk",
    path: "/litigations/:id()",
    meta: _91id_93euy4Skj7axMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue").then(m => m.default || m)
  },
  {
    name: "litigations-id___fr",
    path: "/litigations/:id()",
    meta: _91id_93euy4Skj7axMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue").then(m => m.default || m)
  },
  {
    name: "litigations-id___de",
    path: "/litigations/:id()",
    meta: _91id_93euy4Skj7axMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue").then(m => m.default || m)
  },
  {
    name: "litigations-id___es",
    path: "/litigations/:id()",
    meta: _91id_93euy4Skj7axMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue").then(m => m.default || m)
  },
  {
    name: "litigations-id___nl",
    path: "/litigations/:id()",
    meta: _91id_93euy4Skj7axMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/[id].vue").then(m => m.default || m)
  },
  {
    name: "litigations___en",
    path: "/litigations",
    meta: indexYeloGMYCVsMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/index.vue").then(m => m.default || m)
  },
  {
    name: "litigations___uk",
    path: "/litigations",
    meta: indexYeloGMYCVsMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/index.vue").then(m => m.default || m)
  },
  {
    name: "litigations___fr",
    path: "/litigations",
    meta: indexYeloGMYCVsMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/index.vue").then(m => m.default || m)
  },
  {
    name: "litigations___de",
    path: "/litigations",
    meta: indexYeloGMYCVsMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/index.vue").then(m => m.default || m)
  },
  {
    name: "litigations___es",
    path: "/litigations",
    meta: indexYeloGMYCVsMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/index.vue").then(m => m.default || m)
  },
  {
    name: "litigations___nl",
    path: "/litigations",
    meta: indexYeloGMYCVsMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/litigations/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: indexFiOYC0RAW1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___uk",
    path: "/login",
    meta: indexFiOYC0RAW1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/connexion",
    meta: indexFiOYC0RAW1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/einloggen",
    meta: indexFiOYC0RAW1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/conexion",
    meta: indexFiOYC0RAW1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___nl",
    path: "/inloggen",
    meta: indexFiOYC0RAW1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-redirect___en",
    path: "/login/redirect",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/redirect.vue").then(m => m.default || m)
  },
  {
    name: "login-redirect___uk",
    path: "/login/redirect",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/redirect.vue").then(m => m.default || m)
  },
  {
    name: "login-redirect___fr",
    path: "/login/redirect",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/redirect.vue").then(m => m.default || m)
  },
  {
    name: "login-redirect___de",
    path: "/login/redirect",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/redirect.vue").then(m => m.default || m)
  },
  {
    name: "login-redirect___es",
    path: "/conexi%C3%B3n",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/redirect.vue").then(m => m.default || m)
  },
  {
    name: "login-redirect___nl",
    path: "/aanmelden",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/login/redirect.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___uk",
    path: "/maintenance",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___fr",
    path: "/maintenance",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___de",
    path: "/maintenance",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___es",
    path: "/maintenance",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___nl",
    path: "/maintenance",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter-id___en",
    path: "/marketing/newsletter/:id()",
    meta: _91id_93oqCM6KhhsTMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter-id___uk",
    path: "/marketing/newsletter/:id()",
    meta: _91id_93oqCM6KhhsTMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter-id___fr",
    path: "/marketing/newsletter/:id()",
    meta: _91id_93oqCM6KhhsTMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter-id___de",
    path: "/marketing/newsletter/:id()",
    meta: _91id_93oqCM6KhhsTMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter-id___es",
    path: "/marketing/newsletter/:id()",
    meta: _91id_93oqCM6KhhsTMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter-id___nl",
    path: "/marketing/newsletter/:id()",
    meta: _91id_93oqCM6KhhsTMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter___en",
    path: "/marketing/newsletter",
    meta: indexhVN4sFRWuWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter___uk",
    path: "/marketing/newsletter",
    meta: indexhVN4sFRWuWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter___fr",
    path: "/marketing/newsletter",
    meta: indexhVN4sFRWuWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter___de",
    path: "/marketing/newsletter",
    meta: indexhVN4sFRWuWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter___es",
    path: "/marketing/newsletter",
    meta: indexhVN4sFRWuWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-newsletter___nl",
    path: "/marketing/newsletter",
    meta: indexhVN4sFRWuWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments-id___en",
    path: "/marketing/segments/:id()",
    meta: _91id_93v6IrNed6ZSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments-id___uk",
    path: "/marketing/segments/:id()",
    meta: _91id_93v6IrNed6ZSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments-id___fr",
    path: "/marketing/segments/:id()",
    meta: _91id_93v6IrNed6ZSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments-id___de",
    path: "/marketing/segments/:id()",
    meta: _91id_93v6IrNed6ZSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments-id___es",
    path: "/marketing/segments/:id()",
    meta: _91id_93v6IrNed6ZSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments-id___nl",
    path: "/marketing/segments/:id()",
    meta: _91id_93v6IrNed6ZSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments___en",
    path: "/marketing/segments",
    meta: indexJJxIyv858uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments___uk",
    path: "/marketing/segments",
    meta: indexJJxIyv858uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments___fr",
    path: "/marketing/segments",
    meta: indexJJxIyv858uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments___de",
    path: "/marketing/segments",
    meta: indexJJxIyv858uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments___es",
    path: "/marketing/segments",
    meta: indexJJxIyv858uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue").then(m => m.default || m)
  },
  {
    name: "marketing-segments___nl",
    path: "/marketing/segments",
    meta: indexJJxIyv858uMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketing/segments/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-detail-id___en",
    path: "/auction/:id()",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketplace-detail-id___uk",
    path: "/auction/:id()",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketplace-detail-id___fr",
    path: "/enchere/:id()",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketplace-detail-id___de",
    path: "/auktion/:id()",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketplace-detail-id___es",
    path: "/subastas/:id()",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketplace-detail-id___nl",
    path: "/veiling/:id()",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketplace___en",
    path: "/p/:slug(.*)*",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace___uk",
    path: "/p/:slug(.*)*",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace___fr",
    path: "/p/:slug(.*)*",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace___de",
    path: "/p/:slug(.*)*",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace___es",
    path: "/p/:slug(.*)*",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace___nl",
    path: "/p/:slug(.*)*",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-redirect___en",
    path: "/marketplace",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-redirect___uk",
    path: "/marketplace",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-redirect___fr",
    path: "/marketplace",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-redirect___de",
    path: "/marketplace",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-redirect___es",
    path: "/marketplace",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-redirect___nl",
    path: "/marketplace",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/marketplace/redirect.vue").then(m => m.default || m)
  },
  {
    name: "messages-id___en",
    path: "/messages/:id()",
    meta: _91id_93pUD12kVspSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages-id___uk",
    path: "/messages/:id()",
    meta: _91id_93pUD12kVspSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages-id___fr",
    path: "/messages/:id()",
    meta: _91id_93pUD12kVspSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages-id___de",
    path: "/messages/:id()",
    meta: _91id_93pUD12kVspSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages-id___es",
    path: "/messages/:id()",
    meta: _91id_93pUD12kVspSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages-id___nl",
    path: "/messages/:id()",
    meta: _91id_93pUD12kVspSMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages___en",
    path: "/messages",
    meta: indexRsx4yR7ZI3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages___uk",
    path: "/messages",
    meta: indexRsx4yR7ZI3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages___fr",
    path: "/messages",
    meta: indexRsx4yR7ZI3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages___de",
    path: "/messages",
    meta: indexRsx4yR7ZI3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages___es",
    path: "/messages",
    meta: indexRsx4yR7ZI3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages___nl",
    path: "/messages",
    meta: indexRsx4yR7ZI3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-seller___en",
    path: "/messages/seller",
    meta: indexi7XeucDW0LMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-seller___uk",
    path: "/messages/seller",
    meta: indexi7XeucDW0LMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-seller___fr",
    path: "/messages/seller",
    meta: indexi7XeucDW0LMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-seller___de",
    path: "/messages/seller",
    meta: indexi7XeucDW0LMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-seller___es",
    path: "/messages/seller",
    meta: indexi7XeucDW0LMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-seller___nl",
    path: "/messages/seller",
    meta: indexi7XeucDW0LMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/messages/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id___en",
    path: "/offers/:id()",
    meta: indexfhIQvhMUtEMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id___uk",
    path: "/offers/:id()",
    meta: indexfhIQvhMUtEMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id___fr",
    path: "/offers/:id()",
    meta: indexfhIQvhMUtEMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id___de",
    path: "/offers/:id()",
    meta: indexfhIQvhMUtEMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id___es",
    path: "/offers/:id()",
    meta: indexfhIQvhMUtEMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id___nl",
    path: "/offers/:id()",
    meta: indexfhIQvhMUtEMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step1___en",
    path: "/offers/:id()/step1",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step1___uk",
    path: "/offers/:id()/step1",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step1___fr",
    path: "/offers/:id()/step1",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step1___de",
    path: "/offers/:id()/step1",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step1___es",
    path: "/offers/:id()/step1",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step1___nl",
    path: "/offers/:id()/step1",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step1.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step2___en",
    path: "/offers/:id()/step2",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step2___uk",
    path: "/offers/:id()/step2",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step2___fr",
    path: "/offers/:id()/step2",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step2___de",
    path: "/offers/:id()/step2",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step2___es",
    path: "/offers/:id()/step2",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step2___nl",
    path: "/offers/:id()/step2",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step2.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step3___en",
    path: "/offers/:id()/step3",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step3___uk",
    path: "/offers/:id()/step3",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step3___fr",
    path: "/offers/:id()/step3",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step3___de",
    path: "/offers/:id()/step3",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step3___es",
    path: "/offers/:id()/step3",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue").then(m => m.default || m)
  },
  {
    name: "offers-id-step3___nl",
    path: "/offers/:id()/step3",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/[id]/step3.vue").then(m => m.default || m)
  },
  {
    name: "offers-composition___en",
    path: "/offers/composition",
    meta: compositionfTOtduk28NMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/composition.vue").then(m => m.default || m)
  },
  {
    name: "offers-composition___uk",
    path: "/offers/composition",
    meta: compositionfTOtduk28NMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/composition.vue").then(m => m.default || m)
  },
  {
    name: "offers-composition___fr",
    path: "/offers/composition",
    meta: compositionfTOtduk28NMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/composition.vue").then(m => m.default || m)
  },
  {
    name: "offers-composition___de",
    path: "/offers/composition",
    meta: compositionfTOtduk28NMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/composition.vue").then(m => m.default || m)
  },
  {
    name: "offers-composition___es",
    path: "/offers/composition",
    meta: compositionfTOtduk28NMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/composition.vue").then(m => m.default || m)
  },
  {
    name: "offers-composition___nl",
    path: "/offers/composition",
    meta: compositionfTOtduk28NMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/composition.vue").then(m => m.default || m)
  },
  {
    name: "offers___en",
    path: "/offers",
    meta: indexoVV5oinc0CMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___uk",
    path: "/offers",
    meta: indexoVV5oinc0CMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___fr",
    path: "/offers",
    meta: indexoVV5oinc0CMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___de",
    path: "/offers",
    meta: indexoVV5oinc0CMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___es",
    path: "/offers",
    meta: indexoVV5oinc0CMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___nl",
    path: "/offers",
    meta: indexoVV5oinc0CMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id___en",
    path: "/orders/:id()",
    meta: indexRF0BYF2ESaMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id___uk",
    path: "/orders/:id()",
    meta: indexRF0BYF2ESaMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id___fr",
    path: "/orders/:id()",
    meta: indexRF0BYF2ESaMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id___de",
    path: "/orders/:id()",
    meta: indexRF0BYF2ESaMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id___es",
    path: "/orders/:id()",
    meta: indexRF0BYF2ESaMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id___nl",
    path: "/orders/:id()",
    meta: indexRF0BYF2ESaMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___en",
    path: "/orders",
    meta: indexqCGSkPO5zeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___uk",
    path: "/orders",
    meta: indexqCGSkPO5zeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___fr",
    path: "/orders",
    meta: indexqCGSkPO5zeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___de",
    path: "/orders",
    meta: indexqCGSkPO5zeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___es",
    path: "/orders",
    meta: indexqCGSkPO5zeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___nl",
    path: "/orders",
    meta: indexqCGSkPO5zeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-tracking-id___en",
    path: "/orders/tracking/:id?",
    meta: _91_91id_93_933ppJWzAcqdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "orders-tracking-id___uk",
    path: "/orders/tracking/:id?",
    meta: _91_91id_93_933ppJWzAcqdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "orders-tracking-id___fr",
    path: "/orders/tracking/:id?",
    meta: _91_91id_93_933ppJWzAcqdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "orders-tracking-id___de",
    path: "/orders/tracking/:id?",
    meta: _91_91id_93_933ppJWzAcqdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "orders-tracking-id___es",
    path: "/orders/tracking/:id?",
    meta: _91_91id_93_933ppJWzAcqdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "orders-tracking-id___nl",
    path: "/orders/tracking/:id?",
    meta: _91_91id_93_933ppJWzAcqdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/orders/tracking/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "ping___en",
    path: "/ping",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "ping___uk",
    path: "/ping",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "ping___fr",
    path: "/ping",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "ping___de",
    path: "/ping",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "ping___es",
    path: "/ping",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "ping___nl",
    path: "/ping",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "products-create___en",
    path: "/products/create",
    meta: createXUYkNV8OxPMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___uk",
    path: "/products/create",
    meta: createXUYkNV8OxPMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___fr",
    path: "/products/create",
    meta: createXUYkNV8OxPMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___de",
    path: "/products/create",
    meta: createXUYkNV8OxPMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___es",
    path: "/products/create",
    meta: createXUYkNV8OxPMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create___nl",
    path: "/products/create",
    meta: createXUYkNV8OxPMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products-import-id___en",
    path: "/products/import/:id()",
    meta: _91id_93sjQfYtu0sdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-import-id___uk",
    path: "/products/import/:id()",
    meta: _91id_93sjQfYtu0sdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-import-id___fr",
    path: "/products/import/:id()",
    meta: _91id_93sjQfYtu0sdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-import-id___de",
    path: "/products/import/:id()",
    meta: _91id_93sjQfYtu0sdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-import-id___es",
    path: "/products/import/:id()",
    meta: _91id_93sjQfYtu0sdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-import-id___nl",
    path: "/products/import/:id()",
    meta: _91id_93sjQfYtu0sdMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-import-excel___en",
    path: "/products/import/excel",
    meta: excelLruRM5BpaJMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue").then(m => m.default || m)
  },
  {
    name: "products-import-excel___uk",
    path: "/products/import/excel",
    meta: excelLruRM5BpaJMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue").then(m => m.default || m)
  },
  {
    name: "products-import-excel___fr",
    path: "/products/import/excel",
    meta: excelLruRM5BpaJMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue").then(m => m.default || m)
  },
  {
    name: "products-import-excel___de",
    path: "/products/import/excel",
    meta: excelLruRM5BpaJMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue").then(m => m.default || m)
  },
  {
    name: "products-import-excel___es",
    path: "/products/import/excel",
    meta: excelLruRM5BpaJMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue").then(m => m.default || m)
  },
  {
    name: "products-import-excel___nl",
    path: "/products/import/excel",
    meta: excelLruRM5BpaJMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/excel.vue").then(m => m.default || m)
  },
  {
    name: "products-import___en",
    path: "/products/import",
    meta: index0KlAPorZLWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/index.vue").then(m => m.default || m)
  },
  {
    name: "products-import___uk",
    path: "/products/import",
    meta: index0KlAPorZLWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/index.vue").then(m => m.default || m)
  },
  {
    name: "products-import___fr",
    path: "/products/import",
    meta: index0KlAPorZLWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/index.vue").then(m => m.default || m)
  },
  {
    name: "products-import___de",
    path: "/products/import",
    meta: index0KlAPorZLWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/index.vue").then(m => m.default || m)
  },
  {
    name: "products-import___es",
    path: "/products/import",
    meta: index0KlAPorZLWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/index.vue").then(m => m.default || m)
  },
  {
    name: "products-import___nl",
    path: "/products/import",
    meta: index0KlAPorZLWMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/index.vue").then(m => m.default || m)
  },
  {
    name: "products-import-truck___en",
    path: "/products/import/truck",
    meta: truckn6DgAGvGdxMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue").then(m => m.default || m)
  },
  {
    name: "products-import-truck___uk",
    path: "/products/import/truck",
    meta: truckn6DgAGvGdxMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue").then(m => m.default || m)
  },
  {
    name: "products-import-truck___fr",
    path: "/products/import/truck",
    meta: truckn6DgAGvGdxMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue").then(m => m.default || m)
  },
  {
    name: "products-import-truck___de",
    path: "/products/import/truck",
    meta: truckn6DgAGvGdxMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue").then(m => m.default || m)
  },
  {
    name: "products-import-truck___es",
    path: "/products/import/truck",
    meta: truckn6DgAGvGdxMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue").then(m => m.default || m)
  },
  {
    name: "products-import-truck___nl",
    path: "/products/import/truck",
    meta: truckn6DgAGvGdxMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/products/import/truck.vue").then(m => m.default || m)
  },
  {
    name: "promo-cards-id___en",
    path: "/promo-cards/:id()",
    meta: _91id_93A6UUPmY7NuMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "promo-cards-id___uk",
    path: "/promo-cards/:id()",
    meta: _91id_93A6UUPmY7NuMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "promo-cards-id___fr",
    path: "/promo-cards/:id()",
    meta: _91id_93A6UUPmY7NuMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "promo-cards-id___de",
    path: "/promo-cards/:id()",
    meta: _91id_93A6UUPmY7NuMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "promo-cards-id___es",
    path: "/promo-cards/:id()",
    meta: _91id_93A6UUPmY7NuMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "promo-cards-id___nl",
    path: "/promo-cards/:id()",
    meta: _91id_93A6UUPmY7NuMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "promo-cards___en",
    path: "/promo-cards",
    meta: indexcXacvPhL8AMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-cards___uk",
    path: "/promo-cards",
    meta: indexcXacvPhL8AMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-cards___fr",
    path: "/promo-cards",
    meta: indexcXacvPhL8AMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-cards___de",
    path: "/promo-cards",
    meta: indexcXacvPhL8AMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-cards___es",
    path: "/promo-cards",
    meta: indexcXacvPhL8AMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "promo-cards___nl",
    path: "/promo-cards",
    meta: indexcXacvPhL8AMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/promo-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "register-activity___en",
    path: "/account/activity",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-activity___uk",
    path: "/account/activity",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-activity___fr",
    path: "/compte/activite",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-activity___de",
    path: "/account/activity",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-activity___es",
    path: "/account/activity",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-activity___nl",
    path: "/account/activity",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-buyer___en",
    path: "/register/buyer",
    meta: buyer41hHlxjMx3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/buyer.vue").then(m => m.default || m)
  },
  {
    name: "register-buyer___uk",
    path: "/register/buyer",
    meta: buyer41hHlxjMx3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/buyer.vue").then(m => m.default || m)
  },
  {
    name: "register-buyer___fr",
    path: "/inscription/acheteur",
    meta: buyer41hHlxjMx3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/buyer.vue").then(m => m.default || m)
  },
  {
    name: "register-buyer___de",
    path: "/registrieren/kaufer",
    meta: buyer41hHlxjMx3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/buyer.vue").then(m => m.default || m)
  },
  {
    name: "register-buyer___es",
    path: "/registro/compradores",
    meta: buyer41hHlxjMx3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/buyer.vue").then(m => m.default || m)
  },
  {
    name: "register-buyer___nl",
    path: "/inschrijving/koper",
    meta: buyer41hHlxjMx3Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/buyer.vue").then(m => m.default || m)
  },
  {
    name: "register-confirmation___en",
    path: "/account/confirmation",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "register-confirmation___uk",
    path: "/account/confirmation",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "register-confirmation___fr",
    path: "/compte/confirmation",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "register-confirmation___de",
    path: "/account/confirmation",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "register-confirmation___es",
    path: "/account/confirmation",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "register-confirmation___nl",
    path: "/account/confirmation",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/register",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register___uk",
    path: "/register",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register___fr",
    path: "/register",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register___de",
    path: "/register",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register___es",
    path: "/register",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register___nl",
    path: "/register",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register-profile___en",
    path: "/account/profile",
    meta: profileCEPDV7cwLAMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/profile.vue").then(m => m.default || m)
  },
  {
    name: "register-profile___uk",
    path: "/account/profile",
    meta: profileCEPDV7cwLAMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/profile.vue").then(m => m.default || m)
  },
  {
    name: "register-profile___fr",
    path: "/compte/profil",
    meta: profileCEPDV7cwLAMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/profile.vue").then(m => m.default || m)
  },
  {
    name: "register-profile___de",
    path: "/account/profile",
    meta: profileCEPDV7cwLAMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/profile.vue").then(m => m.default || m)
  },
  {
    name: "register-profile___es",
    path: "/account/profile",
    meta: profileCEPDV7cwLAMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/profile.vue").then(m => m.default || m)
  },
  {
    name: "register-profile___nl",
    path: "/account/profile",
    meta: profileCEPDV7cwLAMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/profile.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-activity___en",
    path: "/register/seller/activity",
    meta: activityRPkLmtpFP8Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-activity___uk",
    path: "/register/seller/activity",
    meta: activityRPkLmtpFP8Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-activity___fr",
    path: "/register/seller/activity",
    meta: activityRPkLmtpFP8Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-activity___de",
    path: "/register/seller/activity",
    meta: activityRPkLmtpFP8Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-activity___es",
    path: "/register/seller/activity",
    meta: activityRPkLmtpFP8Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-activity___nl",
    path: "/register/seller/activity",
    meta: activityRPkLmtpFP8Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/activity.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-goodbye___en",
    path: "/register/seller/goodbye",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-goodbye___uk",
    path: "/register/seller/goodbye",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-goodbye___fr",
    path: "/register/seller/goodbye",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-goodbye___de",
    path: "/register/seller/goodbye",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-goodbye___es",
    path: "/register/seller/goodbye",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "register-seller-goodbye___nl",
    path: "/register/seller/goodbye",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/goodbye.vue").then(m => m.default || m)
  },
  {
    name: "register-seller___en",
    path: "/register/seller",
    meta: indexbK29KU1H33Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "register-seller___uk",
    path: "/register/seller",
    meta: indexbK29KU1H33Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "register-seller___fr",
    path: "/register/seller",
    meta: indexbK29KU1H33Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "register-seller___de",
    path: "/register/seller",
    meta: indexbK29KU1H33Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "register-seller___es",
    path: "/register/seller",
    meta: indexbK29KU1H33Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "register-seller___nl",
    path: "/register/seller",
    meta: indexbK29KU1H33Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/register/seller/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token___en",
    path: "/reset-password/:token()",
    meta: _91token_93l9aVrz6sJeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token___uk",
    path: "/reset-password/:token()",
    meta: _91token_93l9aVrz6sJeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token___fr",
    path: "/reset-password/:token()",
    meta: _91token_93l9aVrz6sJeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token___de",
    path: "/reset-password/:token()",
    meta: _91token_93l9aVrz6sJeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token___es",
    path: "/reset-password/:token()",
    meta: _91token_93l9aVrz6sJeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token___nl",
    path: "/reset-password/:token()",
    meta: _91token_93l9aVrz6sJeMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "suppliers-add___en",
    path: "/suppliers/add",
    meta: addJF1eWoU6zmMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: "suppliers-add___uk",
    path: "/suppliers/add",
    meta: addJF1eWoU6zmMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: "suppliers-add___fr",
    path: "/suppliers/add",
    meta: addJF1eWoU6zmMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: "suppliers-add___de",
    path: "/suppliers/add",
    meta: addJF1eWoU6zmMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: "suppliers-add___es",
    path: "/suppliers/add",
    meta: addJF1eWoU6zmMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: "suppliers-add___nl",
    path: "/suppliers/add",
    meta: addJF1eWoU6zmMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/add.vue").then(m => m.default || m)
  },
  {
    name: "suppliers___en",
    path: "/suppliers",
    meta: indexV73dpxaTLDMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "suppliers___uk",
    path: "/suppliers",
    meta: indexV73dpxaTLDMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "suppliers___fr",
    path: "/suppliers",
    meta: indexV73dpxaTLDMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "suppliers___de",
    path: "/suppliers",
    meta: indexV73dpxaTLDMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "suppliers___es",
    path: "/suppliers",
    meta: indexV73dpxaTLDMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "suppliers___nl",
    path: "/suppliers",
    meta: indexV73dpxaTLDMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/suppliers/index.vue").then(m => m.default || m)
  },
  {
    name: "transports-id___en",
    path: "/transports/:id()",
    meta: _91id_939xM9ZPL6W1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/[id].vue").then(m => m.default || m)
  },
  {
    name: "transports-id___uk",
    path: "/transports/:id()",
    meta: _91id_939xM9ZPL6W1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/[id].vue").then(m => m.default || m)
  },
  {
    name: "transports-id___fr",
    path: "/transports/:id()",
    meta: _91id_939xM9ZPL6W1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/[id].vue").then(m => m.default || m)
  },
  {
    name: "transports-id___de",
    path: "/transports/:id()",
    meta: _91id_939xM9ZPL6W1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/[id].vue").then(m => m.default || m)
  },
  {
    name: "transports-id___es",
    path: "/transports/:id()",
    meta: _91id_939xM9ZPL6W1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/[id].vue").then(m => m.default || m)
  },
  {
    name: "transports-id___nl",
    path: "/transports/:id()",
    meta: _91id_939xM9ZPL6W1Meta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/[id].vue").then(m => m.default || m)
  },
  {
    name: "transports___en",
    path: "/transports",
    meta: indexbr2g73UJOrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/index.vue").then(m => m.default || m)
  },
  {
    name: "transports___uk",
    path: "/transports",
    meta: indexbr2g73UJOrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/index.vue").then(m => m.default || m)
  },
  {
    name: "transports___fr",
    path: "/transports",
    meta: indexbr2g73UJOrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/index.vue").then(m => m.default || m)
  },
  {
    name: "transports___de",
    path: "/transports",
    meta: indexbr2g73UJOrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/index.vue").then(m => m.default || m)
  },
  {
    name: "transports___es",
    path: "/transports",
    meta: indexbr2g73UJOrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/index.vue").then(m => m.default || m)
  },
  {
    name: "transports___nl",
    path: "/transports",
    meta: indexbr2g73UJOrMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/transports/index.vue").then(m => m.default || m)
  },
  {
    name: "trucks-id___en",
    path: "/trucks/:id()",
    meta: _91id_933FHwJSEc5WMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue").then(m => m.default || m)
  },
  {
    name: "trucks-id___uk",
    path: "/trucks/:id()",
    meta: _91id_933FHwJSEc5WMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue").then(m => m.default || m)
  },
  {
    name: "trucks-id___fr",
    path: "/trucks/:id()",
    meta: _91id_933FHwJSEc5WMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue").then(m => m.default || m)
  },
  {
    name: "trucks-id___de",
    path: "/trucks/:id()",
    meta: _91id_933FHwJSEc5WMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue").then(m => m.default || m)
  },
  {
    name: "trucks-id___es",
    path: "/trucks/:id()",
    meta: _91id_933FHwJSEc5WMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue").then(m => m.default || m)
  },
  {
    name: "trucks-id___nl",
    path: "/trucks/:id()",
    meta: _91id_933FHwJSEc5WMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/[id].vue").then(m => m.default || m)
  },
  {
    name: "trucks___en",
    path: "/trucks",
    meta: indexR6GESbVflXMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/index.vue").then(m => m.default || m)
  },
  {
    name: "trucks___uk",
    path: "/trucks",
    meta: indexR6GESbVflXMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/index.vue").then(m => m.default || m)
  },
  {
    name: "trucks___fr",
    path: "/trucks",
    meta: indexR6GESbVflXMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/index.vue").then(m => m.default || m)
  },
  {
    name: "trucks___de",
    path: "/trucks",
    meta: indexR6GESbVflXMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/index.vue").then(m => m.default || m)
  },
  {
    name: "trucks___es",
    path: "/trucks",
    meta: indexR6GESbVflXMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/index.vue").then(m => m.default || m)
  },
  {
    name: "trucks___nl",
    path: "/trucks",
    meta: indexR6GESbVflXMeta || {},
    component: () => import("/builds/stocklear/nuxt-frontend/pages/trucks/index.vue").then(m => m.default || m)
  },
  {
    name: "version___en",
    path: "/version",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/version.vue").then(m => m.default || m)
  },
  {
    name: "version___uk",
    path: "/version",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/version.vue").then(m => m.default || m)
  },
  {
    name: "version___fr",
    path: "/version",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/version.vue").then(m => m.default || m)
  },
  {
    name: "version___de",
    path: "/version",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/version.vue").then(m => m.default || m)
  },
  {
    name: "version___es",
    path: "/version",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/version.vue").then(m => m.default || m)
  },
  {
    name: "version___nl",
    path: "/version",
    component: () => import("/builds/stocklear/nuxt-frontend/pages/version.vue").then(m => m.default || m)
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub3nsAySkTXq
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stub3nsAySkTXq
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/eu-sitemap.xml",
    component: component_45stub3nsAySkTXq
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/uk-sitemap.xml",
    component: component_45stub3nsAySkTXq
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stub3nsAySkTXq
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stub3nsAySkTXq
  },
  {
    name: component_45stub3nsAySkTXqMeta?.name,
    path: "/nl-sitemap.xml",
    component: component_45stub3nsAySkTXq
  }
]